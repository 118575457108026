import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Logo from '../../images/respkt-logo.svg';
import { mq, remCalc, vwMinMax } from '../../stylehelpers/mixins';
import { breakpoint, color } from '../../stylehelpers/variables';
import LinkWrapper from '../linkwrapper/linkwrapper.component';
import ProgressBar from '../progressbar/progressbar.component';

/**
 * Navigation Wrapper
 * @type {StyledComponent}
 */
const Wrapper = styled.nav`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: 0.4s;
  ${({ sticky }) =>
    sticky ? `background-color: white; opacity: 1;` : `background-color: transparent; opacity: 0;`}

  ${mq.smallOnly`
      display: block;
      padding: ${remCalc(10)};
      text-align: center;
    `};

  ${mq.medium`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${remCalc(10, 20)};
    `};

  a {
    display: inline-block !important;
  }
`;

/**
 * Navigation Logo
 * @type {StyledComponent}
 */
const NavigationLogo = styled.img`
  width: 150px;
  height: auto;

  ${mq.smallOnly`
        margin: 0 auto;
        text-align: center;
        width: 120px;
        height: auto;
    `};

  ${({ active }) => (active ? `display: block; ` : `display: none;`)}
`;

/**
 * Navigation Liste
 * @type {StyledComponent}
 */
const NavList = styled.ul`
  list-style-type: none;
  display: flex;
  padding: 0px;
  position: relative;
  height: 100%;

  ${mq.smallOnly`
    justify-content: space-around;
    margin-bottom: 0px;
    margin-top: 10px;
    `};
`;

/**
 * Navigation Listen Item
 * @type {StyledComponent}
 */
const NavListItem = styled.li`
    ${mq.medium`
        margin-right: ${remCalc(10)};
  `};

    transition: 0.2s;
    padding: 0px 10px;

    a {
        ${({ sticky }) => (sticky ? `color: ${color.text}!important;` : `color: white!important;`)}
        ${mq.smallOnly`
                    ${({ active }) =>
                      active
                        ? `border-bottom: 2px solid ${color.primary}; color: ${color.primary}!important;`
                        : `border-bottom: none;`}
        `}

        ${vwMinMax('font-size', 14, 18, breakpoint.medium)}
    }

    &:hover {
        transform: scale(1.05);
    }
`;

const NavBubble = styled.div`
  display: none;

  ${mq.medium`
        margin: 10px auto 0px auto;
        transition: 0.2s;
        min-width: 20px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid ${color.primary};
        box-sizing: border-box;
        display: block;
        transform: scale(1);
        ${({ active }) =>
          active
            ? `background-color: ${color.primary};
                    transform: scale(1.4);`
            : `background-color: white;`}
    `}
`;

const NavLine = styled.div`
  display: none;

  ${mq.medium`
        position: absolute;
        bottom: 9px;
        right: -15px;
        width: 93%;
        height: 2px;
        z-index: -1;
        display: block;
        background-color: ${color.primary};
    `}
`;

/**
 * Navigation Komponente
 * @param {array} props.items Navigationsitems
 * @param {number} props.progress Progress des Scrollens von 0 bis 1
 */
const Navigation = ({ children, items, progress }) => {
  const [pageFragment, setPageFragment] = useState(null);

  /**
   * Setzt aktuellen Seitenbereich aktiv
   */
  const setActivePageFragment = activePageFragment => {
    setPageFragment(activePageFragment);
  };

  /**
   * Erstellt Navigationsleiste
   */
  const navItems = items.map(item => {
    return (
      <NavListItem
        active={pageFragment === item.id}
        sticky={pageFragment !== 'start'}
        key={item.id}
      >
        <LinkWrapper fontSize="s" linkColor={color.text} to={item.to} offset={-20}>
          {item.text}
          <NavBubble sticky={pageFragment !== 'start'} active={pageFragment === item.id} />
        </LinkWrapper>
      </NavListItem>
    );
  });

  return (
    <>
      <Wrapper sticky={pageFragment !== 'start'}>
        <LinkWrapper to="#start" offset={0}>
          <NavigationLogo active={pageFragment !== 'start'} src={Logo} alt="respkt Logo" />
        </LinkWrapper>
        <NavList>
          {navItems}
          <NavLine sticky={pageFragment !== 'start'} />
        </NavList>
      </Wrapper>
      <ProgressBar display={pageFragment !== 'start'} progress={progress} />
      {children({ setActivePageFragment })}
    </>
  );
};

Navigation.propTypes = {
  children: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      to: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
  progress: PropTypes.number.isRequired,
};

export default Navigation;

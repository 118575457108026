import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { gapAble, remCalc } from '../../stylehelpers/mixins';

const defaultProps = {
  sidePadding: 0,
};

const propTypes = {
  children: PropTypes.node,
  width: PropTypes.number,
  mb: PropTypes.number,
  center: PropTypes.bool,
  gap: PropTypes.string,
  textAlign: PropTypes.string,
  sidePadding: PropTypes.number,
};

/**
 * Styled Container
 * @type {StyledComponent}
 */
const StyledContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    ${({ width }) => (width ? `max-width: ${remCalc(width)};` : `maxwidth: 100%;`)}
    ${({ mb }) => (mb ? `margin-bottom: ${remCalc(mb)};` : `margin-bottom: 0px;`)}
    ${({ center }) => (center ? `margin: 0 auto;` : `margin: 0px;`)}
    ${({ gap }) => (gap ? gapAble(gap) : null)}
    ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : `text-align: left;`)}
    ${({ sidePadding }) => (sidePadding !== 0 ? `padding: 0px ${sidePadding}px` : `padding: 0;`)}
`;

/**
 * Wrapper für texte, Bilder, Abschnitte etc
 * @param {number} props.width Prozent der Max width
 * @param {number} props.mb Margin bottom
 * @param {boolean} props.center Zentriert den Container
 * @param {string} props.gap Abstand des Containers
 * @param {string} props.textAlign Ausrichtung des Textes innerhalb des Containers
 */
const Container = ({ children, width, mb, center, gap, textAlign, sidePadding }) => {
  return (
    <StyledContainer
      sidePadding={sidePadding}
      textAlign={textAlign}
      width={width}
      mb={mb}
      center={center}
      gap={gap}
    >
      {children}
    </StyledContainer>
  );
};

Container.defaultProps = defaultProps;
Container.propTypes = propTypes;

export default Container;

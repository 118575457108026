import { remCalc } from './mixins';
/**
 * Farben
 * @type {Object}
 */
export const color = {
  primary: '#AFA073',
  black: '#000000',
  white: '#ffffff',
  text: '#3D4146',
  bg: '#F1F1F5',

  // state colors
  success: '#3adb76',
  warning: '#ffae00',
  alert: '#cc4b37',
};

/**
 * Schriften
 * @type {Object}
 */
export const fontFamily = {
  base: 'Franklin Gothic',
  headline: 'Book',
};

/**
 * Breakpoints
 * @type {Object}
 */
export const breakpoint = {
  small: 0,
  medium: 640,
  large: 1200,
  mlarge: 1500,
  xlarge: 1600,
  xxlarge: 1920,
  xxxlarge: 2560,
};

/**
 * Schriftgrößen
 * @type {Object}
 */
export const fontSizes = {
  inherit: 'inherit',
  base: `font-size: ${remCalc(16)}; font-size: var(--fs-base);`,
  h1: `font-size: ${remCalc(40)}; font-size: var(--fs-h1);`,
  h2: `font-size: ${remCalc(30)}; font-size: var(--fs-h2);`,
  h3: `font-size: ${remCalc(22)}; font-size: var(--fs-h3);`,
  h4: `font-size: ${remCalc(20)}; font-size: var(--fs-h4);`,
  h5: `font-size: ${remCalc(18)}; font-size: var(--fs-h5);`,
  h6: `font-size: ${remCalc(16)}; font-size: var(--fs-h6);`,
  xxxxl: `font-size: ${remCalc(50)}; font-size: var(--fs-xxxxl);`,
  xxxl: `font-size: ${remCalc(40)}; font-size: var(--fs-xxxl);`,
  xxl: `font-size: ${remCalc(32)}; font-size: var(--fs-xxl);`,
  xl: `font-size: ${remCalc(26)}; font-size: var(--fs-xl);`,
  l: `font-size: ${remCalc(20)}; font-size: var(--fs-l);`,
  m: `font-size: ${remCalc(18)}; font-size: var(--fs-m);`,
  s: `font-size: ${remCalc(16)}; font-size: var(--fs-s);`,
  xs: `font-size: ${remCalc(14)}; font-size: var(--fs-xs);`,
};

/**
 * Schriftgrößen
 * @type {Object}
 */
export const fluidFontSizes = {
  h1: [31, 65],
  h2: [31, 65],
  h3: [25, 35],
  h4: [20, 25],
  h5: [16, 20],
  h6: [15, 18],
  xxxxl: [40, 65],
  xxxl: [35, 50],
  xxl: [28, 40],
  xl: [24, 30],
  l: [18, 22],
  m: [16, 20],
  s: [15, 18],
  xs: [14, 16],
};

/**
 * Abstände
 * @type {Object}
 */
export const gaps = {
  none: 'margin-bottom: 0',
  s: `margin-bottom: ${remCalc(4)}; margin-bottom: var(--gap-s);`,
  m: `margin-bottom: ${remCalc(8)}; margin-bottom: var(--gap-m);`,
  l: `margin-bottom: ${remCalc(16)}; margin-bottom: var(--gap-l);`,
  xl: `margin-bottom: ${remCalc(24)}; margin-bottom: var(--gap-xl);`,
  xxl: `margin-bottom: ${remCalc(32)}; margin-bottom: var(--gap-xxl);`,
  xxxl: `margin-bottom: ${remCalc(48)}; margin-bottom: var(--gap-xxxl);`,
  xxxxl: `margin-bottom: ${remCalc(60)}; margin-bottom: var(--gap-xxxxl);`,
};

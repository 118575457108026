import { createGlobalStyle } from 'styled-components';
import { fontFamily, color, breakpoint } from './variables';
import { remCalc, mq } from './mixins';

export default createGlobalStyle`

:root {
        /* Font-Sizes */

        /* Font-Sizes */
        --fs-base: ${remCalc(15)};

        --fs-h1: ${remCalc(31)};
        --fs-h2: ${remCalc(31)};
        --fs-h3: ${remCalc(25)};
        --fs-h4: ${remCalc(20)};
        --fs-h5: ${remCalc(16)};
        --fs-h6: ${remCalc(15)};

        --fs-xxxxl: ${remCalc(40)};
        --fs-xxxl: ${remCalc(35)};
        --fs-xxl: ${remCalc(28)};
        --fs-xl: ${remCalc(24)};
        --fs-l: ${remCalc(18)};
        --fs-m: ${remCalc(16)};
        --fs-s: ${remCalc(14)};
        --fs-xs: ${remCalc(12)};

        /* Gaps */
        --gap-s: ${remCalc(2)};
        --gap-m: ${remCalc(6)};
        --gap-l: ${remCalc(10)};
        --gap-xl: ${remCalc(12)};
        --gap-xxl: ${remCalc(16)};
        --gap-xxxl: ${remCalc(24)};
        --gap-xxxxl: ${remCalc(30)};
    }

    @media screen and (min-width: ${breakpoint.medium}px) {
        :root {
        
            /* Font-Sizes */
            --fs-base: ${remCalc(16)};

            --fs-h1: ${remCalc(40)};
            --fs-h2: ${remCalc(40)};
            --fs-h3: ${remCalc(25)};
            --fs-h4: ${remCalc(21)};
            --fs-h5: ${remCalc(18)};
            --fs-h6: ${remCalc(16)};

            --fs-xxxxl: ${remCalc(50)};
            --fs-xxxl: ${remCalc(40)};
            --fs-xxl: ${remCalc(32)};
            --fs-xl: ${remCalc(26)};
            --fs-l: ${remCalc(20)};
            --fs-m: ${remCalc(18)};
            --fs-s: ${remCalc(16)};
            --fs-xs: ${remCalc(14)};

            /* Gaps */
            --gap-s: ${remCalc(3)};
            --gap-m: ${remCalc(7)};
            --gap-l: ${remCalc(14)};
            --gap-xl: ${remCalc(20)};
            --gap-xxl: ${remCalc(24)};
            --gap-xxxl: ${remCalc(30)};
            --gap-xxxxl: ${remCalc(40)};
        }
    }

    @media screen and (min-width: ${breakpoint.large}px) {
        :root {

            /* Font-Sizes */
            --fs-base: ${remCalc(16)};

            /* Font-Sizes */
            --fs-h1: ${remCalc(65)};
            --fs-h2: ${remCalc(65)};
            --fs-h3: ${remCalc(35)};
            --fs-h4: ${remCalc(25)};
            --fs-h5: ${remCalc(20)};
            --fs-h6: ${remCalc(18)};

            --fs-xxxxl: ${remCalc(65)};
            --fs-xxxl: ${remCalc(50)};
            --fs-xxl: ${remCalc(40)};
            --fs-xl: ${remCalc(30)};
            --fs-l: ${remCalc(22)};
            --fs-m: ${remCalc(20)};
            --fs-s: ${remCalc(16)};
            --fs-xs: ${remCalc(14)};

            /* Gaps */
            --gap-s: ${remCalc(4)};
            --gap-m: ${remCalc(8)};
            --gap-l: ${remCalc(16)};
            --gap-xl: ${remCalc(24)};
            --gap-xxl: ${remCalc(32)};
            --gap-xxxl: ${remCalc(48)};
            --gap-xxxxl: ${remCalc(60)};
        }
    }

    address {
        font-style: normal;
        font-family: ${fontFamily.base};
    }

    html {
        font-size: ${remCalc(16)};
    }

    body {
        margin: 0;
    }

    ul, li {
        font-family: ${fontFamily.base};
        color: ${color.text};
    }

    p {
        line-height: 1.4;
        margin: 0 0 0.25em 0;
        font-family: ${fontFamily.base};
        color: ${color.text};
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: ${fontFamily.headline};
        color: ${color.primary};
        font-weight: normal;
        margin: 0 0 0.25em 0;
        line-height: 1em;
    }

    .progress-icon {
        position: fixed;
        top: 50%;
        left: 20px;
        width: 120px;
        height: 120px;
    }
`;

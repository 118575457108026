import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Grid,
  GridCell,
  Paragraph,
  Section,
  Container,
  Headline,
  GridContainer,
  LinkWrapper,
} from '../../components';

import { color } from '../../stylehelpers/variables';
import { mq, remCalc } from '../../stylehelpers/mixins';

import Form from '../../images/forms/form-3.svg';

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      ctaText: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      linkText: PropTypes.string.isRequired,
    })
  ).isRequired,
};

/**
 * Styled Form Image
 * @type {StyledComponent}
 */
const StyledForm = styled.img`
  max-width: ${remCalc(60)};
  ${mq.medium`
        max-width: ${remCalc(110)};
    `}
`;

/**
 * Headline Helper
 * @type {StyledComponent}
 */
const HeadlineHolder = styled.div`
  margin-top: 0px;

  ${mq.medium`
        margin-top: ${remCalc(20)};
    `}
`;

/**
 * Image helper
 * @type {StyledComponent}
 */
const ImageHolder = styled.div`
  max-width: 100px;
  margin: 0;

  ${mq.medium`
        margin: 0 auto;
    `}
`;

/**
 * Container Holder
 * @type {StyledComponent}
 */
const ContainerHolder = styled.div`
  margin-right: ${remCalc(20)};
`;

/**
 * Kontakt Komponente
 * @param {array} props.data Daten mit Text
 */
const Contact = ({ data }) => {
  return (
    <Section>
      <GridContainer width={1350}>
        <Grid>
          <GridCell large={2} medium={2}>
            <ImageHolder>
              <StyledForm src={Form} alt="Form" />
            </ImageHolder>
          </GridCell>
          <GridCell large={10} medium={10} alignSelf="middle">
            <Container>
              <HeadlineHolder>
                <Headline level="h2">Wie Sie uns erreichen</Headline>
              </HeadlineHolder>
            </Container>
            <Grid>
              {data.map(m => {
                return (
                  <GridCell large={4} gridMargin={0} key={m.id}>
                    <ContainerHolder>
                      <Container gap="xxl">
                        <Paragraph fontSize="s">{m.text}</Paragraph>
                        <Paragraph fontSize="s" gap="m">
                          {m.ctaText}
                        </Paragraph>
                        <LinkWrapper linkColor={color.primary} gap="l" fontSize="l" to={m.link}>
                          {m.linkText}
                        </LinkWrapper>
                      </Container>
                    </ContainerHolder>
                  </GridCell>
                );
              })}
            </Grid>
          </GridCell>
        </Grid>
      </GridContainer>
    </Section>
  );
};

Contact.propTypes = propTypes;

export default Contact;

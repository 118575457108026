import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { breakpoint, color } from '../../stylehelpers/variables';
import { vwMinMax } from '../../stylehelpers/mixins';

const defaultProps = {
  size: [30, 60],
};

const propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  size: PropTypes.array,
  bg: PropTypes.bool,
};

/**
 * Styled Section
 * @type {StyledComponent}
 */
const StyledSection = styled.div`
  ${({ bg }) => (bg ? `background-color: ${color.bg};` : `background-color: none;`)}

  ${({ size }) => `${vwMinMax('padding', [size[0], 0], [size[1], 0], breakpoint.medium)}`}
`;

/**
 * Section Komponente
 * @param {string} props.id Id für Anchorlinks
 * @param {boolean} props.bg Fügt einen grauen Hintergrund hinzu
 * @param {array} props.size Padding für mobile und Desktop
 */
const Section = ({ children, id, size, bg }) => {
  return (
    <StyledSection size={size} bg={bg} id={id}>
      {children}
    </StyledSection>
  );
};

Section.defaultProps = defaultProps;
Section.propTypes = propTypes;

export default Section;

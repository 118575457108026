import React from 'react';
import Img from 'gatsby-image';

import { Container, Headline, Paragraph } from '../../components';

const Card = ({ image, name, title }) => {
  return (
    <Container width={300} textAlign="center" center gap="xl">
      <Container gap="s">
        <Img fluid={image}></Img>
      </Container>
      <Headline level="h4">{name}</Headline>
      <Paragraph fontSize="s"> {title} </Paragraph>
    </Container>
  );
};
export default Card;

import React from 'react';
import styled from 'styled-components';

import { Container, Grid, GridCell, GridContainer, Headline } from '../../components';
import { breakpoint, color } from '../../stylehelpers/variables';
import Logo from '../../images/logo-short.svg';
import { fontSizable, mq, remCalc, vwMinMax } from '../../stylehelpers/mixins';
import BackgroundImage from '../../images/banner-bg-1.jpg';
import Section from '../section/section.component';

/**
 * Banner wrapper
 * @type {StyledComponent}
 */
const Wrapper = styled.div`
    background-color: ${color.text};
    ${vwMinMax('padding', 15, 35, breakpoint.medium)}
    background-image: url(${BackgroundImage});
`;

/**
 * Styled Image
 * @type {StyledComponent}
 */
const StyledImage = styled.img`
  display: block;
  max-width: 100px;
  margin: 0 auto 15px auto;

  ${mq.large`
        max-width: 120px;
    `}
`;

/**
 * Styled Headline
 * @type {StyledComponent}
 */
const StyledHeadline = styled.h2`
  ${vwMinMax('font-size', 25, 65, breakpoint.medium)}
  line-height: 120%;
  color: white;
  display: inline-block;
`;

/**
 * Styled p
 * @type {StyledComponent}
 */
const StyledParagraph = styled.p`
  color: white;
  ${vwMinMax('font-size', 14, 18, breakpoint.medium)}
`;

/**
 * Linke Linie
 * @type {StyledComponent}
 */
const LineLeft = styled.hr`
  color: ${color.primary};
  border: 1px solid ${color.primary};
  width: 70%;
  padding-left: ${remCalc(50)};
  margin-left: 0px;
`;

/**
 * Holder
 * @type {StyledComponent}
 */
const Holder = styled.div`
  text-align: center;

  ${mq.medium`
        text-align: left;
    `}
`;

/**
 * Rechte Linie
 * @type {StyledComponent}
 */
const LineRight = styled.hr`
  color: ${color.primary};
  width: 60%;
  border: 1px solid ${color.primary};
  margin-right: 0px;
`;

/**
 * Respkt Banner Komponente
 */
const Banner = () => {
  return (
    <Section id="leistungen" size={[100, 110]}>
      <Wrapper>
        <GridContainer width={1000}>
          <Container center textAlign="center">
            <StyledImage src={Logo} />
            <StyledHeadline level="h2">WERTVOLL / STARK / WACH</StyledHeadline>
            <StyledParagraph>#wearerespkt</StyledParagraph>
            <LineLeft />
            <LineRight />
          </Container>
        </GridContainer>
      </Wrapper>
    </Section>
  );
};
export default Banner;

import React from 'react';
import Img from 'gatsby-image';

import { Container, ImageGrid, SliderComponent, GridContainer } from '../../components';

const Showcase = ({ data }) => {
  const showCases = data.body.map(s => {
    switch (s.slice_type) {
      case 'multi_showcase':
        return (
          <Container key={s.id}>
            <ImageGrid
              image1={s.primary.multi_showcase_left.localFile.childImageSharp.fluid}
              image2={s.primary.multi_showcase_upper.localFile.childImageSharp.fluid}
              image3={s.primary.multi_showcase_lower.localFile.childImageSharp.fluid}
            />
          </Container>
        );
      case 'single_showcase':
        return (
          <Container key={s.id}>
            <Img fluid={s.primary.single_showcase_image.localFile.childImageSharp.fluid}></Img>
          </Container>
        );
    }
  });

  return (
    <GridContainer width={1350} padding>
      <SliderComponent>{showCases}</SliderComponent>
    </GridContainer>
  );
};
export default Showcase;

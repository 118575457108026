import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { colors, fontFaces, fontSizes, fontWeights, gapable, widths, gaps } from '../../utils';
import Link from '../link/link.component';

const Container = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: ${widths.smallContent};
  width: 100%;
  ${gapable('l')};
`;

const Title = styled.h2`
  color: ${colors.gold};
  font-size: ${fontSizes.xxl};
`;

const Content = styled.div`
  font-size: ${fontSizes.s};
  font-weight: ${fontWeights.bold};
  line-height: 1.3em;
  margin-bottom: ${gaps.l};
`;

const CTA = styled(Link)`
  color: ${colors.gold};
  display: block;
  font-family: ${fontFaces.headline};
  font-size: ${fontSizes.l};
  font-weight: ${fontWeights.bold};
  text-decoration: none;
`;

/**
 * Renders a headline, content and a link underneath eachother
 * @param {object} props
 */
const Teaser = ({ title, children, cta, gap }) => (
  <Container gap={gap}>
    <Title>{title}</Title>
    <Content>{children}</Content>
    <CTA to={cta.href}>{cta.text}</CTA>
  </Container>
);

Teaser.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  cta: PropTypes.shape({ href: PropTypes.string.isRequired, text: PropTypes.string.isRequired })
    .isRequired,
  gap: PropTypes.string,
};

Teaser.defaultProps = {
  gap: null,
};

export default Teaser;

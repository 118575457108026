import React from 'react';

import styled from 'styled-components';
import { color } from '../../stylehelpers/variables';

/**
 * Linke Linie
 * @type {StyledComponent}
 */
const LineLeft = styled.hr`
  color: ${color.primary};
  border: 1px solid ${color.primary};
  width: 45%;
  margin-left: 15%;
`;

/**
 * Rechte Linie
 * @type {StyledComponent}
 */
const LineRight = styled.hr`
  color: ${color.primary};
  width: 45%;
  border: 1px solid ${color.primary};
  margin-right: 15%;
`;

/**
 * Trenner Linie
 */
const Line = () => {
  return (
    <div>
      <LineLeft />
      <LineRight />
    </div>
  );
};
export default Line;

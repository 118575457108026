import React from 'react';

import styled from 'styled-components';
import { color } from '../../stylehelpers/variables';
import { getFontSizes } from '../../utils/utils';

/**
 * Headline Komponente
 * @param {string} props.level Art der Überschrift (h1, h2, h3, etc...)
 * @param {string} props.className Klassenname der Überschrift
 */
const Headline = styled(({ level, children, className, size }) => {
  const HL = level;

  return (
    <HL className={className} size={size}>
      {children}
    </HL>
  );
})`
  ${({ level }) => `${getFontSizes(level)}`}
  color: ${color.primary};
  font-weight: normal;
  line-height: 1.2;
  margin-top: 0;
`;
export default Headline;

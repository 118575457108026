import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';

import {
  Container,
  Grid,
  GridCell,
  GridContainer,
  Headline,
  Paragraph,
  Section,
} from '../../components';
import Form from '../../images/forms/form-2.svg';
import { mq, remCalc } from '../../stylehelpers/mixins';

const StyledForm = styled.img`
    max-width: ${remCalc(60)};
    ${mq.medium`
        max-width: ${remCalc(80)};
    `}
    margin-bottom: ${remCalc(30)};
`;

/**
 * Inside Komponente
 * @param {string} props.groundImage Bild des Bodens mit respkt Logo
 */
const Inside = ({ groundImage }) => {
  return (
    <Section size={[50, 80]}>
      <GridContainer width={1500}>
        <Grid>
          <GridCell large={6}>
            <Container width={642} center>
              <Img fluid={groundImage}></Img>
            </Container>
          </GridCell>
          <GridCell large={6} alignSelf="middle">
            <Container>
              <StyledForm src={Form} alt="Form" />
              <Container width={750}>
                <Headline level="h2">Weshalb wir immer etwas tiefer gehen</Headline>
                <Container width={650}>
                  <Paragraph fontSize="s" gap="l">
                    Bei allem was wir für Sie leisten, kratzen wir nicht an der Oberfläche, sondern
                    gehen den Dingen auf den Grund. Wir tauchen in die Materie ein, sind neugierig,
                    hinterfragen und denken uns in Sie, Ihre Zielgruppe und die Aufgabenstellung
                    hinein.
                  </Paragraph>
                  <Paragraph fontSize="s" gap="l">
                    So finden wir heraus, was Ihre Produkte und Services wirklich einzigartig macht,
                    wie Ihre potenziellen Kunden ticken, wo wir sie treffen und wie wir sie am
                    besten ansprechen. Daraus entsteht Kommunikation mit Tiefgang, die stark und
                    nachhaltig für Sie wirkt.
                  </Paragraph>
                  <Paragraph fontSize="s">re:spkt inside – und alles wird gut!</Paragraph>
                </Container>
              </Container>
            </Container>
          </GridCell>
        </Grid>
      </GridContainer>
    </Section>
  );
};
export default Inside;

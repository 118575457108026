import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { fontFamily } from '../../stylehelpers/variables';
import { fontSizable, gapAble } from '../../stylehelpers/mixins';
import { getLinkType } from '../../utils/utils';
import { AnchorLink } from '../../components';

const propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  fontSize: PropTypes.string,
  gap: PropTypes.string,
  linkColor: PropTypes.string,
};

/**
 * Styled link
 * @type {StyledComponent}
 */
const StyledLink = styled.a`
    ${({ fontSize }) => fontSizable(fontSize)}
    ${({ gap }) => (gap ? gapAble(gap) : null)}
    color: ${({ linkColor }) => linkColor || `white`};
    font-family: ${fontFamily.headline};
    text-decoration: none;
    font-weight: 600;
`;

/**
 * Styled Gatsbylink
 * @type {StyledComponent}
 */
const StyledGatsbyLink = styled(Link)`
  color: white;
  text-decoration: none;
`;

/**
 * Link Komponente
 * @param {string} props.to Linkziel
 * @param {string} props.fontSize Schriftgröße des Links
 * @param {string} props.gap Abstand nach unten
 * @param {string} props.fontSize Schriftgröße des Links
 * @param {string} props.offset Versatz der Stelle des Ziels
 */
const LinkWrapper = ({ children, to, fontSize, gap, linkColor, offset }) => {
  const linkType = getLinkType(to);

  if (linkType === 'anchor') {
    return (
      <AnchorLink href={to} offset={offset}>
        {children}
      </AnchorLink>
    );
  } else if (linkType === 'internal') {
    return <StyledGatsbyLink to={to}>{children}</StyledGatsbyLink>;
  } else if (linkType === 'mailphone') {
    return (
      <StyledLink linkColor={linkColor} gap={gap} fontSize={fontSize} href={to}>
        {' '}
        {children}
      </StyledLink>
    );
  } else {
    return (
      <StyledLink
        linkColor={linkColor}
        gap={gap}
        fontSize={fontSize}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
      >
        {' '}
        {children}
      </StyledLink>
    );
  }
};

LinkWrapper.propTypes = propTypes;

export default LinkWrapper;

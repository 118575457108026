import React from 'react';
import styled from 'styled-components';
import { color } from '../../stylehelpers/variables';
import { mq, remCalc } from '../../stylehelpers/mixins';

import Logo from '../../images/logo-short.svg';

const StyledProgressBar = styled.div`
  display: none;
  transition: 0.2s;
  ${mq.mlarge`
        ${({ display }) => (display ? `opacity: 1;` : `opacity: 0;`)}
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        z-index: 10000;
        left: 20px;
        top: 40%;
    `}
`;

const StyledLogo = styled.img`
  max-width: ${remCalc(50)};
  height: auto;
  margin-bottom: ${remCalc(20)};
`;

const Bar = styled.div`
  background-color: ${color.primary};
  height: ${remCalc(220)};
  width: 2px;
  position: relative;
`;

const Circle = styled.div`
  position: absolute;
  height: ${remCalc(16)};
  width: ${remCalc(16)};
  border-radius: 50%;
  background-color: white;
  border: 2px solid ${color.primary};
  left: 50%;
  transform: translateX(-50%);
`;

const ProgressBar = ({ progress, display }) => {
  return (
    <StyledProgressBar display={display}>
      <StyledLogo src={Logo} alt="respkt" />
      <Bar>
        <Circle style={{ top: `${progress * 100}%` }} />
      </Bar>
    </StyledProgressBar>
  );
};
export default ProgressBar;

import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { color, fontFamily } from '../../stylehelpers/variables';
import { mq, fontSizable, remCalc } from '../../stylehelpers/mixins';
import { Container, Paragraph, Anim } from '../../components';

const defaultProps = {
  title: '',
  accordionContent: [],
  image: '',
};

const propTypes = {
  title: PropTypes.string,
  accordionContent: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.string,
};

/**
 * Styled Accordion content
 * @type {StyledComponent}
 */
const StyledContent = styled.div`
  max-height: 0px;
  transition: 0.2s;
  overflow: hidden;
  animation-name: anim;
`;

const StyledP = styled(Paragraph)`
  margin-bottom: 20px !important;
`;

/**
 * Accordion wrapper
 * @type {StyledComponent}
 */
const Wrapper = styled.div`
  position: relative;
  ${mq.medium`padding: 30px; min-height: 280px;`}
  border: 2px solid ${color.primary};
  text-align: center;
  padding: ${remCalc(15)};
  margin-bottom: ${remCalc(10)};
  cursor: pointer;
  height: 100%;
  box-sizing: border-box;
  transition: 0.2s;
  transform: translateY(0px);
  ${({ active }) => (active ? `background-color: ${color.primary};` : `background-color: white;`)}

  &::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: calc(50% - 10px);
    ${({ active }) =>
      active ? `border-top: 10px solid ${color.primary};` : `border-top: 10px solid transparent;`}
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }

  &:hover {
    background-color: ${color.primary};
  }
`;

/**
 * Styled headline
 * @type {StyledComponent}
 */
const StyledHeadline = styled.h3`
    ${({ active, hover }) => (active || hover ? `color: white;` : `color: ${color.primary};`)}
    ${fontSizable('h4')}
    ${mq.medium`margin-top: ${remCalc(20)};`}
    line-height: 120%;
    display: inline-block;

`;

/**
 * Styled image
 * @type {StyledComponent}
 */
const StyledImage = styled.img`
  width: auto;
  height: 60px;
  ${mq.medium`height: 114px;`}
  ${({ active }) =>
    active ? `filter: invert(100%) sepia(100%) saturate(100%);` : `filter: invert(0);`}
`;

const Divider = styled.div`
  padding-bottom: ${remCalc(20)};
`;

const ContentHeadline = styled.h3`
  margin-top: ${remCalc(10)};
  font-family: ${fontFamily.base};
  color: black;
  line-height: 120%;
`;

const Holder = styled.div``;

/**
 * Icon Accordion Kompoenente
 * @param {string} props.title Titel der Iconbox
 * @param {string} props.accordionContent Inhalt des Accordions
 * @param {string} props.image Icon des Accordions
 */
const Accordion = ({
  title,
  accordionContent,
  image,
  animation,
  accordionIndex,
  handleOpen,
  openId,
  headline,
}) => {
  const content = useRef(null);
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [height, setHeight] = useState('0px');

  /**
   * Setzt Accordion activ wenn es das angeklickte ist
   */
  useEffect(() => {
    if (openId === accordionIndex) {
      setOpen(true);
      setHeight(`${content.current.scrollHeight}px`);
    } else {
      setOpen(false);
      setHeight('0px');
    }
  }, [openId]);

  /**
   * Legt actives Accordion fest
   */
  const toggleAccordion = () => {
    if (open) {
      handleOpen(-1);
    } else {
      handleOpen(accordionIndex);
    }
  };

  return (
    <Holder>
      <Wrapper
        active={open}
        onClick={() => toggleAccordion()}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <Container gap="m" center textAlign="center">
          {open || hover ? (
            <Anim animation={animation} />
          ) : (
            <StyledImage active={open} src={image} />
          )}
        </Container>
        <StyledHeadline hover={hover} active={open}>
          {title}
        </StyledHeadline>
      </Wrapper>
      <StyledContent ref={content} style={{ maxHeight: `${height}` }}>
        <StyledHeadline level="h4">{title}</StyledHeadline>
        <ContentHeadline>{headline}</ContentHeadline>
        {accordionContent.map((data, index) => {
          return (
            <StyledP key={index} fontSize="xs" gap="m">
              {data}
            </StyledP>
          );
        })}
        <Divider />
      </StyledContent>
    </Holder>
  );
};

Accordion.defaultProps = defaultProps;
Accordion.propTypes = propTypes;

export default Accordion;

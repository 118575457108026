import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';

import { Container, Grid, GridCell, GridContainer, Headline, Section } from '../../components';
import { mq } from '../../stylehelpers/mixins';

const Top = styled.div`
  margin-top: 80px;

  ${mq.large`
    margin-top: 100px;
  `}
`;

/**
 * Komponente, die aktuelle Kunden auflistet
 * @param {object} props.data Kundendaten aus Prismic
 */
const Kunden = ({ data }) => {
  return (
    <Section id="referenzen" size={[30, 60]}>
      <Top />
      <GridContainer width={1300}>
        <Container sidePadding={15} gap="xl">
          <Headline level="h2">Wo unsere Ideen wirken</Headline>
        </Container>
        <Grid>
          {data.map((d, index) => {
            return (
              <GridCell key={index} large={3} medium={4} small={6}>
                <Container width={219} gap="xxl">
                  <Img fluid={d.kunden_bild.localFile.childImageSharp.fluid}></Img>
                </Container>
              </GridCell>
            );
          })}
        </Grid>
      </GridContainer>
    </Section>
  );
};
export default Kunden;

import React, { useEffect } from 'react';
import { polyfill } from 'smoothscroll-polyfill';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const defaultProps = {
  href: '',
  offset: 0,
  children: null,
};

const propTypes = {
  href: PropTypes.string,
  offset: PropTypes.number,
  children: PropTypes.node,
};

/**
 * Styled Anchorlink
 * @type {StyledComponent}
 */
const StyledAnchor = styled.a`
  text-decoration: none;
`;

/**
 * Link, der an gewisse Stellen der Webseite springt
 * @param {string} props.href Link zum Abschnitt
 * @param {number} props.offset Versatz zu dem der Link navigiert
 */
const AnchorLink = ({ href, offset, children }) => {
  useEffect(() => {
    polyfill();
  });

  /**
   * Scrollt an die gewünschte Stelle
   */
  const smothScroll = e => {
    e.preventDefault();

    const href = e.currentTarget.getAttribute('href');
    const id = href.slice(href.indexOf('#') + 1);
    const element = document.getElementById(`${id}`);

    window.scroll({
      top: element.getBoundingClientRect().top + window.pageYOffset - offset,
      behavior: 'smooth',
    });
  };

  return (
    <StyledAnchor href={href} onClick={e => smothScroll(e)}>
      {children}
    </StyledAnchor>
  );
};

AnchorLink.propTypes = propTypes;
AnchorLink.defaultProps = defaultProps;

export default AnchorLink;

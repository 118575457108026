import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Checks the link's type
 * @param {string} to The link's href
 * @return {string} Type of the link (external, internal or anchor)
 */
const getLinkType = to => {
  const internal = /^\/(?!\/)/.test(to);
  const anchor = to && to.charAt(0) === '#';
  let type = 'external';

  // Internal (Gatsby-Link)
  if (internal && !anchor) {
    type = 'internal';
  } else if (!internal && anchor) {
    type = 'anchor';
  }

  return type;
};

/**
 * A universal link component
 * @param {string} props.children The children to render
 * @param {string} props.to: The link's href
 */
const Link = ({ className, to, children }) => {
  const linkType = getLinkType(to);

  // External link
  let Element = (
    <a className={className} href={to} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );

  // Internal (Gatsby link)
  if (linkType === 'internal') {
    Element = (
      <GatsbyLink className={className} to={to}>
        {children}
      </GatsbyLink>
    );
  } else if (linkType === 'anchor') {
    Element = (
      <a className={className} href={to}>
        {children}
      </a>
    );
  }

  return Element;
};

Link.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

Link.defaultProps = {
  className: null,
};

export default Link;

import { css } from 'styled-components';
import { breakpoints, gaps } from '../variables/variables.util';

/**
 * Media Queries
 *
 * @example
 *     font-size: 16px;
 *     ${mq.medium`font-size: 20px`};
 */
export const mq = {
  smallOnly: (...args) => css`
    @media (max-width: ${breakpoints.mediumDown}) {
      ${css(...args)};
    }
  `,
  medium: (...args) => css`
    @media (min-width: ${breakpoints.medium}) {
      ${css(...args)};
    }
  `,
  mediumDown: (...args) => css`
    @media (max-width: ${breakpoints.largeDown}) {
      ${css(...args)};
    }
  `,
  mediumOnly: (...args) => css`
    @media (min-width: ${breakpoints.medium}) and (max-width: ${breakpoints.largeDown}) {
      ${css(...args)};
    }
  `,
  large: (...args) => css`
    @media (min-width: ${breakpoints.large}) {
      ${css(...args)};
    }
  `,
  largeDown: (...args) => css`
    @media (max-width: ${breakpoints.xlargeDown}) {
      ${css(...args)};
    }
  `,
  largeOnly: (...args) => css`
    @media (min-width: ${breakpoints.large}) and (max-width: ${breakpoints.xlargeDown}) {
      ${css(...args)};
    }
  `,
  xlarge: (...args) => css`
    @media (min-width: ${breakpoints.xlarge}) {
      ${css(...args)};
    }
  `,
  xlargeDown: (...args) => css`
    @media (max-width: ${breakpoints.xxlargeDown}) {
      ${css(...args)};
    }
  `,
  xlargeOnly: (...args) => css`
    @media (min-width: ${breakpoints.xlarge}) and (max-width: ${breakpoints.xxlargeDown}) {
      ${css(...args)};
    }
  `,
  xxlarge: (...args) => css`
    @media (min-width: ${breakpoints.xxlarge}) {
      ${css(...args)};
    }
  `,
  xxlargeDown: (...args) => css`
    @media (max-width: ${breakpoints.xxxlargeDown}) {
      ${css(...args)};
    }
  `,
  xxlargeOnly: (...args) => css`
    @media (min-width: ${breakpoints.xxlarge}) and (max-width: ${breakpoints.xxxlargeDown}) {
      ${css(...args)};
    }
  `,
  xxxlarge: (...args) => css`
    @media (min-width: ${breakpoints.xxxlarge}) {
      ${css(...args)};
    }
  `,
};

/**
 * Global bottom margins
 *
 * @param {string} defaultGap Optional: der Standard-Abstand
 *
 * @example ${gapable('l')};
 */
export const gapable = (defaultGap = 'none') => ({ gap }) =>
  `margin-bottom: ${gap in gaps ? gaps[`${gap}`] : gaps[`${defaultGap}`]}`;

/**
 * Format a given telephone number into a more readable representation
 * @param {string} tel The telephone number to format ("004971196880810")
 *
 * @example formatTel('004971196880810') => '+49 (0) 711 968 808 10'
 */
export const formatTel = tel =>
  (([t]) => `+${t[1]} (0) ${t[2]} ${t[3]} ${t[4]} ${t[5]}`)([
    ...tel.matchAll(/^00(\d{2})(\d{3})(\d{3})(\d{3})(\d+)/g),
  ]);

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { mq } from '../../stylehelpers/mixins';

const defaultProps = {
  gridMargin: 15,
  large: 3,
  medium: 6,
  small: 12,
};

const propTypes = {
  children: PropTypes.node,
  gridMargin: PropTypes.number,
  large: PropTypes.number,
  medium: PropTypes.number,
  small: PropTypes.number,
  alignSelf: PropTypes.string,
};

/**
 * Grid Wrapper
 * @type {StyledComponent}
 */
const StyledGridCell = styled.div`
    position: relative;
    width: ${({ small, gridMargin }) => (small ? `calc(${small}% - ${gridMargin * 2}px)` : '100%')};
    ${mq.medium`width: ${({ medium, gridMargin }) => `calc(${medium}% - ${gridMargin * 2}px)`};`}
    ${mq.large`width: ${({ large, gridMargin }) => `calc(${large}% - ${gridMargin * 2}px)`};`}
    ${({ gridMargin }) => (gridMargin ? `margin: 0px ${gridMargin}px;` : `margin: 0;`)}
    ${({ gridMargin }) => (gridMargin ? `margin-bottom: ${gridMargin}px;` : null)}
    height: auto;
    box-sizing: border-box;
    display: block;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: auto;
    min-width: 0;
    min-height: 0;
    align-self: ${({ alignSelf }) => (alignSelf === 'middle' ? 'center' : 'initial')};
`;

/**
 * Zelle innerhalb eines Grids (Zahlen müssen immer 12 ergeben)
 * @param {number} props.large Größe bei einem großen breakpoint
 * @param {number} props.medium Größe bei einem großen breakpoint
 * @param {number} props.small Größe bei einem großen breakpoint
 * @param {number} props.gridMargin Abstand der Zellen zueinander
 * @param {string} props.alignSelf Y Ausrichtung der Zelle
 *
 * @example <GridCell large={4} medium={6} small={12} gridMarging={20} alignSelf="middle"
 */
const GridCell = ({ children, large, medium, small, gridMargin, alignSelf }) => {
  /**
   * Berechnet die breite der Zelle
   */
  const calculateGridPercentage = input => {
    switch (input) {
      case 1:
        return 8.33;
      case 2:
        return 16.66;
      case 3:
        return 25;
      case 4:
        return 33.33;
      case 5:
        return 41.66;
      case 6:
        return 50;
      case 7:
        return 58.33;
      case 8:
        return 66.66;
      case 9:
        return 75;
      case 10:
        return 83.33;
      case 11:
        return 91.66;
      case 12:
        return 100;
      default:
        break;
    }
  };

  return (
    <StyledGridCell
      large={calculateGridPercentage(large)}
      medium={calculateGridPercentage(medium)}
      small={calculateGridPercentage(small)}
      gridMargin={gridMargin}
      alignSelf={alignSelf}
    >
      {children}
    </StyledGridCell>
  );
};

GridCell.defaultProps = defaultProps;
GridCell.propTypes = propTypes;

export default GridCell;

import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { mq, fontSizable, remCalc } from '../../stylehelpers/mixins';
import { fontFamily } from '../../stylehelpers/variables';
import { AnchorLink, ScrollDown, Container } from '../../components';

import Fallback from '../../images/wave-fallback.jpg';
import Logo from '../../images/logo-short.svg';

import Wave from '../../images/videos/waves4.mp4';

/**
 * Header Wrapper
 * @type {StyledComponent}
 */
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  ${mq.smallOnly`
    height: 100vh;
  `};

  ${mq.medium`
    height: 100vh;
  `};
`;

/**
 * Hintergrund Bild
 * @type {StyledComponent}
 */
const Background = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 0;
  top: 0;
  z-index: -1;
`;

/**
 * Styled Image
 * @type {StyledComponent}
 */
const StyledImage = styled.img`
  animation-name: anim;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-timing-function: ease-in;

  @keyframes anim {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;

/**
 * Styled Headline
 * @type {StyledComponent}
 */
const HeaderHeadline = styled.h2`
  ${fontSizable('h3')}
  color: white;
  font-family: ${fontFamily.headline};

  min-width: ${remCalc(280)};
  margin-top: ${remCalc(20)};

  animation-name: ani;
  animation-duration: 1s;

  @keyframes ani {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

/**
 * Content des Headers
 * @type {StyledComponent}
 */
const HeaderContent = styled.div`
  text-align: center;
  margin-bottom: ${remCalc(80)};
  padding: 0px 15px;
  width: 100%;

  img {
    width: 150px;
    height: auto;
  }

  ${mq.medium`
        img {
        width: 200px;
        height: auto;
        margin-bottom: ${remCalc(50)};
    }`}
`;

/**
 * Styled Scroll
 * @type {StyledComponent}
 */
const StyledScroll = styled.div`
  position: absolute;
  left: 50%;
  bottom: 15%;
  ${mq.medium`
        bottom: 50px;
    `}
  transform: translateX(-50%);
`;

/**
 * Header Komponente
 */
const Header = () => {
  const [hl, setHl] = useState(['wertvoll / stark / wach', '#wearerespkt']);
  const [counter, setCounter] = useState(0);
  const [name, setName] = useState(hl[counter]);

  /**
   * Ändert das Wort alle 3 Sekunden
   */
  useEffect(() => {
    setName(hl[counter]);
    setTimeout(() => {
      if (counter >= hl.length - 1) {
        setCounter(0);
      } else {
        setCounter(counter + 1);
      }
    }, 5500);
  }, [counter]);

  return (
    <HeaderWrapper id="start">
      <HeaderContent>
        <Container gap="m" center textAlign="center">
          <img src={Logo} alt="respkt Logo"></img>
        </Container>
        <HeaderHeadline key={name}>{name}</HeaderHeadline>
      </HeaderContent>
      {/* 
                <Background src={BG} alt="Bckground image"></Background>
           */}
      <Background autoPlay muted loop playsInline>
        <source src={Wave} type="video/mp4" />
        <img src={Fallback} />
      </Background>
      <StyledScroll>
        <AnchorLink href="#agentur" offset={-50}>
          <ScrollDown />
        </AnchorLink>
      </StyledScroll>
    </HeaderWrapper>
  );
};

export default Header;

import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { address } from '../../../data.yaml';
import Logo from '../../images/logo-batch-negativ.svg';
import { LinkWrapper, Container } from '../../components';

import { fontFamily, color } from '../../stylehelpers/variables';
import { fontSizable, gapAble, vwMinMax, remCalc, mq, formatTel } from '../../stylehelpers/mixins';

import Instagram from '../../images/instagram.svg';
import Linkedin from '../../images/linkedin.svg';
import Xing from '../../images/xing.svg';

/**
 * Footer Wrapper
 * @type {StyledComponent}
 */
const Wrapper = styled.div`
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  background-color: ${color.primary};
  color: white;
  ${vwMinMax('font-size', 16, 18)}
`;
/**
 * Footer content
 * @type {StyledComponent}
 */
const StyledContainer = styled.div`
  font-family: ${fontFamily.base};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${remCalc(10, 0)};
  text-align: center;

  & > * {
    margin-bottom: ${remCalc(20)};
  }

  ${mq.medium`
        flex-direction: row;
        text-align: left;

        & > * {
            margin-bottom: 0px;
        }
    `}
`;

/**
 * Styled adress
 * @type {StyledComponent}
 */
const StyledAdress = styled.address`
  font-style: normal;

  a {
    font-family: ${fontFamily.base}!important;
    ${vwMinMax('font-size', 16, 18)}
  }
`;

const Copyright = styled.div`
  font-family: ${fontFamily.base};
  text-align: center;
`;

const SocialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  ${mq.medium`
        text-align: left;
    `}

  a {
    font-family: ${fontFamily.base}!important;
    ${vwMinMax('font-size', 16, 18)}
  }
`;

const StyledHr = styled.hr`
  border-color: white;
`;

const LinkHolder = styled.div`
  margin: 5px 0px;
  display: flex;
  align-items: flex-end;
  width: 100%;

  img {
    margin-right: 10px;
    max-width: 16px;
  }

  ${mq.medium`
        img {
            margin-right: 10px;
            max-width: 18px;
        }
    `}

  span {
    line-height: 100%;
  }
`;

const Footer = () => (
  <Wrapper>
    <Container width={1200} center>
      <StyledContainer>
        <img alt="Logo" src={Logo} />

        <StyledAdress>
          <strong>{address.name}</strong>
          <br />
          {address.street}
          <br />
          {address.zip} {address.city}
          <br />
        </StyledAdress>
        <StyledAdress>
          T:{' '}
          <LinkWrapper fontSize="s" to={`tel:${address.tel}`}>
            {formatTel(address.tel)}
          </LinkWrapper>
          <br />
          F: {formatTel(address.fax)}
          <br />
          E:{' '}
          <LinkWrapper fontSize="s" to={`mailto:${address.mail}`}>
            {address.mail}
          </LinkWrapper>
          <br />
        </StyledAdress>
        <SocialWrapper>
          <LinkWrapper fontSize="s" to="https://www.instagram.com/we_are_respkt/">
            <LinkHolder>
              <img src={Instagram} alt="Instagram" />
              <span>Instagram</span>
            </LinkHolder>
          </LinkWrapper>
          <LinkWrapper fontSize="s" to="https://www.linkedin.com/company/respkt/">
            <LinkHolder>
              <img src={Linkedin} alt="Linkedin" />
              <span>LinkedIn</span>
            </LinkHolder>
          </LinkWrapper>
          <LinkWrapper fontSize="s" to="https://www.xing.com/companies/re:spktgmbh">
            <LinkHolder>
              <img src={Xing} alt="Xing" />
              <span>Xing</span>
            </LinkHolder>
          </LinkWrapper>
        </SocialWrapper>
        <LinkWrapper fontSize="s" to="/impressum">
          Impressum
        </LinkWrapper>
      </StyledContainer>
      <StyledHr />
      <Copyright>&copy; {new Date().getFullYear()} re:spkt GmbH</Copyright>
    </Container>
  </Wrapper>
);

export default Footer;

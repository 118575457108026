import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { observeIntersection } from '../../utils/utils';

/**
 * Komponente, die einen Callback wirft, sobald sie bzw. ihre Kinder im Viewport ist
 * @param {bool} props.once (Optional)
 * @param {function} props.callback Die Methode die aufgerufen wird, wenn die Kinder im Viewport sind
 * @param {node} props.children Die Kinder
 */
class Observable extends Component {
  static propTypes = {
    callback: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  };

  element = React.createRef();

  observer = null;

  /**
   * Lifecycle Hook bei Mount
   */
  componentDidMount() {
    const { callback } = this.props;
    this.observer = observeIntersection(
      this.element.current,
      entry => {
        if (entry.isIntersecting) {
          callback();
        }
      },
      {
        threshold: 0,
        rootMargin: '0px 0px -100% 0px',
      }
    );
  }

  /**
   * Lifecycle Hook bei Unmount
   */
  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  /**
   * Rendert die Kinder
   */
  render() {
    const { children } = this.props;
    return <div ref={this.element}>{children}</div>;
  }
}

export default Observable;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { fontSizable, gapAble, vwMinMax } from '../../stylehelpers/mixins';
import { breakpoint, fluidFontSizes } from '../../stylehelpers/variables';

const defaultProps = {
  bold: false,
  fontSize: 'm',
};

const propTypes = {
  bold: PropTypes.bool,
  fontSize: PropTypes.string,
  children: PropTypes.node.isRequired,
  gap: PropTypes.string,
};

/**
 * Styled P
 * @type {StyledComponent}
 */
const P = styled.p`
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  margin-top: 0;
  ${({ fontSize }) => fontSizable(fontSize)}
  ${({ gap }) => (gap ? gapAble(gap) : null)}

    &:last-child {
    margin-bottom: 0;
  }

  ${({ fluid }) => `${vwMinMax('font-size', fluid[0], fluid[1], breakpoint.medium)}`};
`;

/**
 * Paragraph
 * @param {boolean} props.bold schriftdicke
 * @param {String} props.fontSize Schriftgröße
 * @param {String} props.gap Abstand nach unten
 */
const Paragraph = ({ bold, fontSize, children, gap }) => {
  const getFontSizes = size => {
    if (fluidFontSizes[size]) {
      return fluidFontSizes[size];
    }

    return fluidFontSizes.m;
  };

  return (
    <P fontSize={fontSize} fluid={getFontSizes(fontSize)} bold={bold} gap={gap}>
      {children}
    </P>
  );
};

Paragraph.defaultProps = defaultProps;
Paragraph.propTypes = propTypes;

export default Paragraph;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  Accordion,
  Container,
  Grid,
  GridCell,
  GridContainer,
  Headline,
  Line,
  Paragraph,
  Section,
} from '../../components';

import Blitz from '../../images/icons/blitz.svg';
import Batch from '../../images/icons/batch.svg';
import Fire from '../../images/icons/fire.svg';
import Flag from '../../images/icons/flag.svg';
import Layer from '../../images/icons/layer.svg';
import PaperPlane from '../../images/icons/paper-plane.svg';

import { mq } from '../../stylehelpers/mixins';

/**
 * Auflistung der Leistungen
 */
const LeistungenData = [
  {
    id: 0,
    title: 'Beratung & Projektmanagement',
    headline: 'Schön, wenn man kompetente Antworten und spürbare Entlastung erhält ',
    text: [
      'Zuhören, verstehen, Möglichkeiten aufzeigen, Strategien entwickeln – und auf unserer Erfahrung basierend, genau die Lösung bieten, die wirkt und Ihren Zielen entspricht. Das ist, was wir unter guter Beratung verstehen. ',
      'Und weil Sie Wichtigeres zu tun haben, als sich mit der Realisierung Ihrer Kommunikationsmaßnahmen zu beschäftigen, erledigen wir das für Sie – in enger Abstimmung mit Ihnen. Wir kümmern uns um jedes Detail, setzen um, buchen, koordinieren und bringen damit auch komplexe Projekte sicher ins Ziel. Im vereinbarten Zeit- und Kostenrahmen, in optimaler Qualität.',
    ],
    image: Batch,
    animation: 'batch',
  },
  {
    id: 1,
    title: 'Strategie & Marke',
    headline: 'Manchmal muss man sich abgrenzen um wirklich gut anzukommen',
    text: [
      'Eine starke Marke ist heute Pflicht – denn wer im Markt sichtbar und erfolgreich sein will, muss sich klar präsentieren, eindeutig positionieren und sich so vom Wettbewerb differenzieren. Das gilt für B2B-Unternehmen ebenso wie für den Bereich B2C. ',
      'Deshalb bieten wir alles, was „no name“ zur unverwechselbaren Marke macht und bestehende Marken nachhaltig stärkt: von der strategischen Planung, Markenworkshop und Markenentwicklung bzw. Markenführung über Logo, Naming und Positionierung bis zum wichtigsten markenrelevanten Werkzeug – den CI-/CD-Guidelines.',
    ],
    image: Flag,
    animation: 'flag',
  },
  {
    id: 2,
    title: 'Konzept & Idee',
    headline: 'Hier steckt viel Köpfchen und eine Menge Herz für Sie drin',
    text: [
      'Sie haben die Aufgabenstellung, wir finden die Lösung – und die basiert immer auf einer starken, auffälligen und unverwechselbaren Idee. ',
      'Die fliegt uns selten zu, sondern muss erarbeitet werden. Dafür beschäftigen wir uns intensiv mit Ihren Produkten bzw. Services sowie den Zielen, die Sie verfolgen und denken uns in Ihre potenziellen Kunden hinein.',
      'Am Ende entstehen aus rationalen Argumenten klare, emotional wirksame Bilder und Botschaften – die bestens ankommen, Vorteile glaubhaft vermitteln und ihre Kraft über alle Kanäle und Werbemittel hinweg entfalten. Ganz sicher. Denn beim Thema Konzept & Idee sind wir echte Überzeugungstäter.',
    ],
    image: Fire,
    animation: 'fire',
  },
  {
    id: 3,
    title: 'Text & Kreation',
    headline: 'Wir kombinieren Worte, die wirken mit bewegenden Bildern ',
    text: [
      'Ob Riesenkampagne oder „Minijob“, ob für Broschüren, Websites, Social Media oder für den Messestand: Alle Texte und Bilder entstehen direkt in unserer Agentur – und orientieren sich immer an der zuvor entwickelten Leitidee.',
      'Wir bringen auch komplexe Sachverhalte auf den Punkt – zeigen, dass selbst SEO- / SEA-Texte lebendig sein können und schreiben so, dass die Botschaften ankommen und man sie leicht versteht. Denn darum geht’s – nicht nur beim Text, sondern auch in der Visualisierung. Und deshalb gestalten wir auffällig schön und modern, aber immer zweck- und zielgruppenorientiert. Einfach so, dass es wirkt: in der klassischen Werbung und auf digitalen Kanälen. Stark, schnell und nachhaltig. Klar!',
    ],
    image: Blitz,
    animation: 'blitz',
  },
  {
    id: 4,
    title: 'Digital',
    headline: 'Weil ohne Online heute nichts mehr geht',
    text: [
      '„Digital First” oder flankierende Online-Maßnahmen für Ihre Kampagne – wir bringen digitale Projekte nachhaltig voran: von Websites, Intranet, Content Management Systemen und Apps über Online-Shops bis hin zu Online- oder Social-Media-Marketing. Wir kennen die Trends – wissen, was ankommt und funktioniert – und haben auch hier immer eine starke Idee.',
      'Basierend auf der von uns entwickelten Digitalstrategie und dem passenden Konzept, ergänzt um professionelle Programmierung und mit Einsatz neuester Technologien führen wir Ihr digitales Projekt zum Erfolg. Ganz real!',
    ],
    image: PaperPlane,
    animation: 'paper-plane',
  },
  {
    id: 5,
    title: 'Produktion',
    headline: 'So kommen Projekte ohne Qualitätsverluste erfolgreich durchs Finale',
    text: [
      'Wir sorgen dafür, dass unsere Ideen und der dazugehörige Content in der gewünschten Qualität und zu den vereinbarten Kosten aufs Papier, ins Web und in die Köpfe Ihrer Zielgruppe kommen. ',
      'Dafür übernehmen wir die Druckdaten-/Template-Erstellung bzw. Programmierung. Wir steuern und kontrollieren die komplette Produktion aller Werbemittel und -maßnahmen: vom einfachen Flyer und der Geschäftsausstattung über aufwendige Imagebroschüren, Mailings und Give-aways bis zur gesamten Online-Präsenz sowie kompletten Messeständen. ',
    ],
    image: Layer,
    animation: 'layer',
  },
];

const HeadlineHolder = styled.div`
  margin-bottom: -12px;

  ${mq.medium`
        margin-bottom: -20px;
    `}
`;

/**
 * Komponente, die die verschiedenen Leistungen von respkt zeigt
 */
const Leistungen = () => {
  const [openId, setOpenId] = useState(-1);

  /**
   * Legt das aktive Accordion fest
   * @param {number} id id des aktiven Accordions
   */
  const handleOpen = id => {
    setOpenId(id);
  };

  return (
    <Section>
      <GridContainer width={1400}>
        <Container sidePadding={15}>
          <HeadlineHolder>
            <Headline level="h2">Was wir leisten</Headline>
          </HeadlineHolder>
          <Headline level="h2">und wie Sie profitieren</Headline>
        </Container>
        <Section size={[15, 25]}>
          <Grid>
            <GridCell large={6}>
              <Container width={600}>
                <Paragraph fontSize="s">
                  re:spkt bietet alles, was Ihre wirksame Unternehmenskommunikation braucht: von der
                  Markenbildung und -führung über Corporate Identity und Corporate Design bis hin zu
                  den konkreten Kommunikationsmaßnahmen in den Bereichen Print, Digital, Messe und
                  Funk.
                </Paragraph>
              </Container>
            </GridCell>
            <GridCell large={6}>
              <Container width={600}>
                <Paragraph fontSize="s">
                  Weil wir ganzheitlich denken und handeln, zählen neben Fotografie, Bewegtbild und
                  Sound auch Programmierung, Performance Marketing und Druckproduktion zu unseren
                  Leistungen. So machen wir Fullservice komplett!
                </Paragraph>
              </Container>
            </GridCell>
          </Grid>
        </Section>

        <Grid>
          {LeistungenData.map((l, index) => {
            return (
              <GridCell large={4} key={index}>
                <Accordion
                  image={l.image}
                  title={l.title}
                  headline={l.headline}
                  accordionContent={l.text}
                  animation={l.animation}
                  accordionIndex={index}
                  handleOpen={handleOpen}
                  openId={openId}
                />
              </GridCell>
            );
          })}
        </Grid>
        <Line />
      </GridContainer>
    </Section>
  );
};
export default Leistungen;

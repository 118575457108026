import { css } from 'styled-components';
import { breakpoint, fontSizes, gaps } from './variables';
import PropTypes from 'prop-types';

/**
 * Mixin um zwischen zwei Breakpoints Pixelwerte fließend in Abhängigkeit des Viewports zu skalieren
 * @param  {String} cssProp CSS Propterty {String}
 * @param  {String} minxPxValue minimaler Pixelwert
 * @param  {String} maxPxValue maximaler Pixelwert
 * @param  {String} minViewportWidth minimaler Viewport, untere Grenze
 * @param  {String} maxViewportWidth maximaler Viewport, obere Grenze
 * @param  {boolean} noMobileMQ Name ist Programm
 * @param  {boolean} usePixel Pixel statt REM
 * @param  {boolean} important Name ist Programm
 * @return {String}
 * @example
 *         ${vwMinMax('font-size', 18, 22, 320, 1920)};
 */
export const vwMinMax = (
    cssProp,
    minPxValue,
    maxPxValue,
    minViewport = breakpoint.small,
    maxViewport = breakpoint.xxlarge,
    noMobileMQ = false,
    usePixel,
    important
) => {
    const vwOrVh = cssProp === 'top' || cssProp === 'bottom' ? 'vh' : 'vw';
    const widthOrHeight = cssProp === 'top' || cssProp === 'bottom' ? 'height' : 'width';
    const viewportIncrease = maxViewport - minViewport;

    const minMax = (min, max) => {
        const increase = max - min;
        return `
            calc(${(increase / viewportIncrease) * 100}${vwOrVh} + ${
            min - (increase / viewportIncrease) * minViewport
        }px)
        `;
    };

    let maxValue = usePixel ? `${maxPxValue}px` : remCalc(maxPxValue);
    let minValue = usePixel ? `${minPxValue}px` : remCalc(minPxValue);
    let minMaxValue = minMax(minPxValue, maxPxValue);

    // Wenn es multiple Werte sind, ggf. für padding etc.
    if (Array.isArray(minPxValue) && Array.isArray(maxPxValue)) {
        maxValue = usePixel
            ? maxPxValue.map((value) => `${value}px`).join(' ')
            : maxPxValue.map((value) => remCalc(value)).join(' ');

        minValue = usePixel
            ? minPxValue.map((value) => `${value}px`).join(' ')
            : minPxValue.map((value) => remCalc(value)).join(' ');

        minMaxValue = minPxValue.map((value, index) => minMax(value, maxPxValue[index])).join(' ');
    }

    const mobileMQ = !noMobileMQ ? `${cssProp}: ${minValue}${important ? ' !important' : ''};` : '';

    return `

       ${mobileMQ};

        @media (min-${widthOrHeight}: ${remCalc(minViewport)}) {
            ${cssProp}: ${minMaxValue}${important ? ' !important' : ''};
        }

        @media (min-${widthOrHeight}: ${remCalc(maxViewport)}) {
            ${cssProp}: ${maxValue}${important ? ' !important' : ''};
        }
    `;
};

/**
 * Mixin, um wiederverwertbare MediaQueries zu erhalten
 * @param  {Object} mq Beliebige Anzahl von Zahlen
 * @return {String} In MQ gewrapptes CSS
 * @example
 *     font-size: 16px;
 *     ${mq.medium`font-size: 20px`};
 */
export const mq = {
    smallOnly: (...args) => css`
        @media (max-width: ${remCalc(breakpoint.medium - 1)}) {
            ${css(...args)};
        }
    `,
    medium: (...args) => css`
        @media (min-width: ${remCalc(breakpoint.medium)}) {
            ${css(...args)};
        }
    `,
    mediumDown: (...args) => css`
        @media (max-width: ${remCalc(breakpoint.medium - 1)}) {
            ${css(...args)};
        }
    `,
    mediumOnly: (...args) => css`
        @media (min-width: ${remCalc(breakpoint.medium)}) and (max-width: ${remCalc(
                breakpoint.large - 1
            )}) {
            ${css(...args)};
        }
    `,
    large: (...args) => css`
        @media (min-width: ${remCalc(breakpoint.large)}) {
            ${css(...args)};
        }
    `,
    largeDown: (...args) => css`
        @media (max-width: ${remCalc(breakpoint.large - 1)}) {
            ${css(...args)};
        }
    `,
    largeOnly: (...args) => css`
        @media (min-width: ${remCalc(breakpoint.large)}) and (max-width: ${remCalc(
                breakpoint.xlarge - 1
            )}) {
            ${css(...args)};
        }
    `,
    xlarge: (...args) => css`
        @media (min-width: ${remCalc(breakpoint.xlarge)}) {
            ${css(...args)};
        }
    `,
    mlarge: (...args) => css`
        @media (min-width: ${remCalc(breakpoint.mlarge)}) {
            ${css(...args)};
        }
    `,
    xLargeDown: (...args) => css`
        @media (max-width: ${remCalc(breakpoint.xlarge - 1)}) {
            ${css(...args)};
        }
    `,
    xlargeOnly: (...args) => css`
        @media (min-width: ${remCalc(breakpoint.xlarge)}) and (max-width: ${remCalc(
                breakpoint.xxlarge - 1
            )}) {
            ${css(...args)};
        }
    `,
    xxlarge: (...args) => css`
        @media (min-width: ${remCalc(breakpoint.xxlarge)}) {
            ${css(...args)};
        }
    `,
    xxLargeDown: (...args) => css`
        @media (max-width: ${remCalc(breakpoint.xxlarge - 1)}) {
            ${css(...args)};
        }
    `,
    xxlargeOnly: (...args) => css`
        @media (min-width: ${remCalc(breakpoint.xxlarge)}) and (max-width: ${remCalc(
                breakpoint.xxxlarge - 1
            )}) {
            ${css(...args)};
        }
    `,
    xxxlarge: (...args) => css`
        @media (min-width: ${remCalc(breakpoint.xxxlarge)}) {
            ${css(...args)};
        }
    `,
};

/**
 * Mixin um Pixel zu REM zu konvertieren
 * @param  {Array} values Beliebige Anzahl von Zahlen
 * @return {String}
 * @example
 *     padding: ${remCalc(5, 20, 0)}; ===> padding: 0.3125rem 1.25rem 0;
 */
export const remCalc = (...values) =>
    values
        .map((value) => {
            const numVal = parseInt(value, 10);

            return numVal === 0 ? '0' : `${numVal / 16}rem`;
        })
        .join(' ');

/**
 * Erweitert Elemente um die Möglichkeit, die Schriftgröße anhand der definierten `fontSizes` zu steuern.
 *
 * @param {number} defaultSize Optional: Die Standard-Größe
 *
 * @example ${fontSizable('m')};
 */
export const fontSizable = (defaultSize = 'm') => (fontSize) =>
    `${fontSizes[fontSize] ? fontSizes[fontSize] : fontSizes[defaultSize]}`;
//fontSizable.propType = PropTypes.oneOf(Object.keys(fontSizes));

export const gapAble = (gap) => {
    return `${gaps[gap]}`;
};

/**
 * Format a given telephone number into a more readable representation
 * @param {string} tel The telephone number to format ("004971196880810")
 *
 * @example formatTel('004971196880810') => '+49 (0) 711 968 808 10'
 */
export const formatTel = (tel) =>
    (([t]) => `+${t[1]} (0) ${t[2]} ${t[3]} ${t[4]} ${t[5]}`)([
        ...tel.matchAll(/^00(\d{2})(\d{3})(\d{3})(\d{3})(\d+)/g),
    ]);

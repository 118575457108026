import React from 'react';
import styled from 'styled-components';

import {
  Container,
  GridContainer,
  Section,
  SliderComponent,
  ReferenzContent,
} from '../../components';

/**
 * Wrapper
 * @type {StyledComponent}
 */
const Wrapper = styled.div`
  position: relative;
`;

/**
 * Komponente, die Referenzen von respkt zeigt
 * @param {object} props.data Referenzen aus Prismic
 */
const Referenzen = ({ data }) => {
  return (
    <Section size={[50, 150]}>
      <GridContainer width={1350} padding>
        <Wrapper>
          <SliderComponent>
            {data.map((d, index) => {
              return (
                <Container key={index}>
                  <ReferenzContent data={d.node.data} />
                </Container>
              );
            })}
          </SliderComponent>
        </Wrapper>
      </GridContainer>
    </Section>
  );
};
export default Referenzen;

import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Image = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "bg.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Img
      className={className}
      style={{ position: 'absolute' }}
      fluid={data.bg.childImageSharp.fluid}
    />
  );
};

Image.propTypes = {
  className: PropTypes.string,
};

Image.defaultProps = {
  className: null,
};

const KeyVisual = styled(Image)`
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
`;

export default KeyVisual;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { fontSizable, gapAble, remCalc } from '../../stylehelpers/mixins';
import { color, fontFamily } from '../../stylehelpers/variables';

import Arrow from '../../images/arrow.svg';

const defaultProps = {
  children: null,
  to: '',
  arrow: true,
  fontSize: 's',
  gap: '',
};

const propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  arrow: PropTypes.bool,
  fontSize: PropTypes.string,
  gap: PropTypes.string,
};

/**
 * Styled Link
 * @type {StyledComponent}
 */
const StyledButton = styled.a`
    cursor: pointer;
    padding: ${remCalc(15, 20)};
    color: white;
    justify-content: space-between;
    text-decoration: none;
    font-family: ${fontFamily.base};
    background-color: ${color.primary};
    ${({ arrow }) => (arrow ? `display: flex;` : `display: block;`)}
    ${({ fontSize }) => fontSizable(fontSize)}
    ${({ gap }) => (gap ? gapAble(gap) : null)}
`;

/**
 * Button Komponente (Aktuell noch nicht ausgebaut, funtkioniert bisher nur als externer Link)
 * @param {string} props.to Ziel des Links
 * @param {string} props.fontSize Schriftgröße des Textes
 * @param {string} props.gap Abstand nach unten
 * @param {boolean} props.arrow Entscheidet ob ein Pfeil im Button enthalten sein soll
 */
const Button = ({ children, to, arrow, fontSize, gap }) => {
  return (
    <StyledButton
      gap={gap}
      fontSize={fontSize}
      arrow={arrow}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
      {arrow && <img src={Arrow} />}
    </StyledButton>
  );
};

Button.defaultProps = defaultProps;
Button.propTypes = propTypes;

export default Button;

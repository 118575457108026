import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';

import {
  Container,
  Grid,
  GridCell,
  GridContainer,
  Headline,
  Paragraph,
  Section,
} from '../../components';

import { mq, fontSizable, vwMinMax } from '../../stylehelpers/mixins';
import { breakpoint, color } from '../../stylehelpers/variables';

/**
 * Styled Upper Headline
 * @type {StyledComponent}
 */
const HeadlineHolder = styled.div`
  margin-left: 0px;
  margin-bottom: -12px;

  ${mq.medium`
        margin-left: -35%;
        margin-bottom: -20px;
    `}
`;

/**
 * Styled List
 * @type {StyledComponent}
 */
const StyledList = styled.ul`
  padding-left: 20px;
`;

/**
 * Styled Lisitem
 * @type {StyledComponent}
 */
const StyledListItem = styled.li`
  padding: 5px 0px;
  ${vwMinMax('font-size', 15, 17, breakpoint.medium)}
`;

const BulletPointLine = styled.div`
  position: absolute;
  z-index: 3;
  right: 15px;
  top: 0;
  height: 100%;
  width: 10%;

  ${mq.medium`
        top: -10%;
        width: 30%;
    `}
`;

const BulletPoint = styled.div`
  margin-left: -11px;
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid ${color.primary};
`;

const BulletLines = styled.div`
  width: 30%;
  height: 50%;
  border-left: 2px solid ${color.primary};
`;

/**
 * Komponente, die die Fähigkeiten von respkt aufzählt
 * @param {string} image Bild eines Schuh's
 */
const Skills = ({ image }) => {
  return (
    <Section size={[30, 100]}>
      <GridContainer width={1500}>
        <Grid>
          <GridCell large={6} alignSelf="middle">
            <Container>
              <BulletPointLine>
                <BulletPoint />
                <BulletLines />
              </BulletPointLine>
              <Img fluid={image}></Img>
            </Container>
          </GridCell>
          <GridCell large={6}>
            <Container width={650} center>
              <Container gap="xxl">
                <HeadlineHolder>
                  <Headline level="h2">Was uns gemeinsam </Headline>
                </HeadlineHolder>
                <Headline level="h2">nach vorne bringt</Headline>
              </Container>
              <Paragraph fontSize="s">
                Fullservice aus einer Hand – von der Beratung bis zur Produktion. Ein Ansatz hinter
                dem wir stehen und ein Versprechen, das wir halten. Weil wir überzeugt sind, dass
                wir damit Tempo, Effizienz und Qualität gewinnen – und Sie deshalb schneller,
                sicherer und besser bei Ihrer Zielgruppe ankommen.
              </Paragraph>
            </Container>
          </GridCell>
        </Grid>
      </GridContainer>
    </Section>
  );
};
export default Skills;

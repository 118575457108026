import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const propTypes = {
  align: PropTypes.string,
  verticalAlign: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  align: null,
  verticalAlign: 'initial',
};

/**
 * Grid Wrapper
 * @type {StyledComponent}
 */
const GridWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  box-sizing: border-box;
  align-items: ${({ verticalAlign }) => verticalAlign || 'flex-start'};
  justify-content: ${({ align }) => align || 'initial'};
`;

/**
 * Grid Komponente
 * @param {string} props.align Das Grid ausrichten
 * @param {string} props.verticalAlign Das Grid ausrichten
 */
const Grid = ({ children, align, verticalAlign }) => {
  return (
    <GridWrapper align={align} verticalAlign={verticalAlign}>
      {children}
    </GridWrapper>
  );
};

Grid.propTypes = propTypes;
Grid.defaultProps = defaultProps;

export default Grid;

import React, { useState } from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';

import {
  Container,
  Grid,
  GridCell,
  GridContainer,
  Section,
  JobDescription,
  Button,
} from '../../components';

import { color, fontFamily, gaps } from '../../stylehelpers/variables';
import { fontSizable, vwMinMax, mq, remCalc } from '../../stylehelpers/mixins';

/**
 * Mehr anzeigen Button
 * @type {StyledComponent}
 */
const MoreButton = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
  margin-bottom: 30px;
  color: ${color.text};
  font-family: ${fontFamily.base};
  border-bottom: 2px solid ${color.primary};
  ${({ fontSize }) => fontSizable(fontSize)}
  margin-left: ${remCalc(20)};
`;

/**
 * Styled Image Container
 * @type {StyledComponent}
 */
const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  margin-bottom: ${remCalc(16)};
`;

/**
 * Gatsby image Style
 * @type {GatsbyImage}
 */
const TeaserImg = styled(Img)`
  height: 100%;

  ${mq.medium`
        min-height: ${remCalc(400)};
   `}
`;

/**
 * Styled Headline
 * @type {StyledComponent}
 */
const StyledHeadline = styled.h2`
  ${vwMinMax('font-size', 31, 65)}
  ${gaps.m}
`;

/**
 * Lower Styled Headline
 * @type {StyledComponent}
 */
const JobHeadline = styled.h2`
  ${vwMinMax('font-size', 25, 35)}
  margin: ${remCalc(0, 20)};
  ${gaps.xl}
`;

/**
 *
 * @param {string} props.image Bild eines respkt Pullis
 * @param {object} props.jobs Stellenangebot Inhalte aus Prismic
 */
const Jobs = ({ image, jobs }) => {
  const initialNumberOfJobs = 4;
  const [slice, setSlice] = useState(initialNumberOfJobs);

  /**
   * Zeigt alle Angebote an
   */
  const showAllSlices = () => {
    setSlice(jobs.length);
  };

  /**
   * Zeigt nur 2 Angebote an
   */
  const removeSlices = () => {
    setSlice(4);
  };

  return (
    <Section id="jobs" size={[40, 180]}>
      <GridContainer width={1600}>
        <Grid>
          <GridCell large={6} gridMargin={20} alignSelf="middle">
            <Container>
              <ImageContainer>
                <TeaserImg fluid={image}></TeaserImg>
              </ImageContainer>
            </Container>
          </GridCell>
          <GridCell large={6} gridMargin={20} alignSelf="middle">
            <Container>
              <Container gap="xl">
                <StyledHeadline>Und außerdem sind wir auch noch nett</StyledHeadline>
              </Container>
              <div>
                {jobs.job_toggle ? (
                  <div>
                    <Container>
                      <JobDescription
                        description={jobs.basis_job_text.html}
                        mail="jobs@respkt.de"
                        subject="Initialbewerbung"
                      />
                    </Container>
                    {/*
                    
                    {jobs.jobs.slice(0, slice).map((data, index) => {
                      return (
                        <JobDescription
                          key={index}
                          title={data.job_title.text}
                          description={data.job_description.html}
                          subject={data.job_title.text}
                          mail={data.job_email.text}
                        />
                      );
                    })}

                    {jobs.jobs.length > 2 ? (
                      <Container>
                        {slice < jobs.jobs.length ? (
                          <MoreButton
                            fontSize="m"
                            onClick={() => {
                              showAllSlices();
                            }}
                          >
                            Alle anzeigen
                          </MoreButton>
                        ) : (
                          <MoreButton
                            fontSize="m"
                            onClick={() => {
                              removeSlices();
                            }}
                          >
                            Weniger anzeigen
                          </MoreButton>
                        )}
                      </Container>
                    ) : null}
                    */}
                  </div>
                ) : (
                  <div>
                    <Container width={500}>
                      <JobDescription
                        description={jobs.basis_job_text.html}
                        mail="jobs@respkt.de"
                        subject="Initialbewerbung"
                      />
                    </Container>
                  </div>
                )}
              </div>
            </Container>
          </GridCell>
        </Grid>
        {jobs.job_toggle && (
          <Container>
            <JobHeadline>Aktuelle Stellenangebote</JobHeadline>
            <Grid>
              {jobs.jobs.slice(0, slice).map((data, index) => {
                return (
                  <GridCell large={6} gridMargin={20}>
                    <JobDescription
                      key={index}
                      title={data.job_title.text}
                      description={data.job_description.html}
                      subject={data.job_title.text}
                      mail={data.job_email.text}
                    />
                  </GridCell>
                );
              })}
              <Container>
                {jobs.jobs.length > initialNumberOfJobs && slice !== jobs.jobs.length && (
                  <div>
                    {slice < jobs.jobs.length ? (
                      <MoreButton
                        fontSize="m"
                        onClick={() => {
                          showAllSlices();
                        }}
                      >
                        Alle anzeigen
                      </MoreButton>
                    ) : (
                      <MoreButton
                        fontSize="m"
                        onClick={() => {
                          removeSlices();
                        }}
                      >
                        Weniger anzeigen
                      </MoreButton>
                    )}
                  </div>
                )}
              </Container>
            </Grid>
          </Container>
        )}
      </GridContainer>
    </Section>
  );
};
export default Jobs;

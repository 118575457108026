import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { mq } from '../../stylehelpers/mixins';
import { GridContainer } from '../../components';

const propTypes = {
  image1: PropTypes.string,
  image2: PropTypes.string,
  image3: PropTypes.string,
};

/**
 * Image Grid
 * @type {StyledComponent}
 */
const StyledImageGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1.2fr 0.8fr;

  ${mq.medium`
        grid-auto-flow: row dense;
        grid-template-rows: 1fr 1.4fr;
        grid-template-columns: 2fr 1fr 1fr;
    `};
`;

/**
 * Großes Bild Link
 * @type {StyledComponent}
 */
const MainImage = styled.div`
  grid-column: 1/2;
  grid-row: 1;
  grid-column-end: span 2;
  -ms-grid-column-span: 2;

  ${mq.medium`
        grid-column: 1 / 3;
        grid-row: 1;
        -ms-grid-column-span: 2;
        -ms-grid-row-span: 2;
        grid-column-end: span 2;
        grid-row-end: span 2;
    `};
`;

/**
 * Bild rechts oben
 * @type {StyledComponent}
 */
const SmallUpperImage = styled.div`
  grid-column: 1;
  grid-row: 2;

  ${mq.medium`
        grid-column: 3;
        grid-row: 1;
    `};
`;

/**
 * Bild rechts unten
 * @type {StyledComponent}
 */
const SmallLowerImage = styled.div`
  grid-column: 2;
  grid-row: 2;

  ${mq.medium`
        grid-column: 3;
        grid-row: 2
    `};
`;

/**
 * Styled image
 * @type {StyledComponent}
 */
const StyledImg = styled(Img)`
  object-fit: cover;
  height: 100%;
`;

/**
 * Image Grid Komponente
 * @param {string} props.image1 Bild links
 * @param {string} props.image2 Bild rechts oben
 * @param {string} props.image3 Bild rechts unten
 */
const ImageGrid = ({ image1, image2, image3 }) => {
  return (
    <GridContainer width={1350}>
      <StyledImageGrid>
        <MainImage>
          {' '}
          <StyledImg fluid={image1}></StyledImg>
        </MainImage>
        <SmallUpperImage>
          {' '}
          <StyledImg fluid={image2}></StyledImg>
        </SmallUpperImage>
        <SmallLowerImage>
          <StyledImg fluid={image3}></StyledImg>
        </SmallLowerImage>
      </StyledImageGrid>
    </GridContainer>
  );
};

ImageGrid.propTypes = propTypes;

export default ImageGrid;

import { fluidFontSizes, breakpoint } from '../stylehelpers/variables';
import { vwMinMax } from '../stylehelpers/mixins';

/**
 * Überprüft, welchen Typ ein Link hat
 * @param {string} to Der Link
 * @return {string} Typ des Links (external, internal oder anchor)
 */
export const getLinkType = to => {
  let type = 'external';

  if (to.startsWith('#')) {
    type = 'anchor';
  } else if (to.startsWith('/')) {
    type = 'internal';
  } else if (to.startsWith('t') || to.startsWith('m')) {
    type = 'mailphone';
  }

  return type;
};

/**
 * Erzeugt einen IntersectionObserver für ein bestimmtest Element
 * @param {HTMLElement} element Element, dass überwacht werden soll
 * @param {Function} callback Funktion die bei Änderungen aufgerufen wird
 * @param {Object} options Optionen des IntersectionObservers (https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/IntersectionObserver)
 * @returns {IntersectionObserver}
 */
export function observeIntersection(element, callback, options) {
  const observer = new IntersectionObserver(
    entries => entries.forEach(entry => callback(entry)),
    options
  );

  observer.observe(element);

  return observer;
}

/**
 * Erzeugt die Schriftgröße
 * @param {object} size Schriftgröße
 */
export const getFontSizes = size => {
  if (fluidFontSizes[size]) {
    const fluid = fluidFontSizes[size];
    const res = vwMinMax('font-size', fluid[0], fluid[1], breakpoint.medium);
    return res;
  }

  const res = vwMinMax('font-size', 16, 18, breakpoint.medium);
  return res;
};

/**
 * Konvertiert einen string in eine URL
 * @param {string} word das Wort
 */
export const convertToUrlString = word => {
  return word.split(' ').join('%20');
};

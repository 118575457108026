import React from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { remCalc } from '../../stylehelpers/mixins';

const propTypes = {
  width: PropTypes.number,
  children: PropTypes.node.isRequired,
  padding: PropTypes.bool,
};

const defaultProps = {
  width: 1200,
  padding: false,
};

/**
 * Grid Container
 * @type {StyledComponent}
 */
const StyledGridContainer = styled.div`
  ${({ width }) => `max-width: ${width}px`};
  margin: 0 auto;
  box-sizing: border-box;
  ${({ padding }) => (padding ? `padding: ${remCalc(0, 15)};` : `padding: 0px;`)}
`;

/**
 * Gridcontainer Komponente
 * @param {number} props.width Breite des Containers
 * @param {boolean} props.padding Ob der Container ein Padding von 15px hat
 */
const GridContainer = ({ width, children, padding }) => {
  return (
    <StyledGridContainer padding={padding} width={width}>
      {children}
    </StyledGridContainer>
  );
};

GridContainer.propTypes = propTypes;
GridContainer.defaultProps = defaultProps;

export default GridContainer;

import PropTypes from 'prop-types';
import React from 'react';
import 'typeface-eb-garamond';
import 'typeface-libre-franklin';
import Footer from '../footer/footer.component';
import SEO from '../seo/seo.component';
import GlobalStyle from '../../stylehelpers/globalStyle';
import '../../assets/fonts/fonts.css';

const Layout = ({ children, address, title }) => (
  <>
    <SEO title={title} />
    <GlobalStyle />

    <div>
      <main>{children}</main>
      <Footer />
    </div>

    <script type="application/ld+json">
      {`{
          "@context": "http://schema.org",
          "@type": "Organization",
          "name": "${address.name}",
          "url": "${address.url}",
          "telephone": "${address.tel}",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "${address.street}",
            "addressLocality": "${address.city}",
            "postalCode": "${address.zip}",
            "addressCountry": "Germany"
          }
        }`}
    </script>
    <script async defer data-domain="respkt.de" src="https://stats.respkt.de/js/index.js" />
  </>
);

Layout.propTypes = {
  address: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    tel: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default Layout;

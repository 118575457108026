import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';

import { Grid, GridCell, Paragraph, Section, Container, Headline } from '../../components';
import { mq, remCalc, vwMinMax } from '../../stylehelpers/mixins';
import Form from '../../images/forms/form-1.svg';

const Wrapper = styled.div``;

const HL = styled.h2`
  ${vwMinMax('font-size', 18, 65, 320, 1920)}
`;

/**
 * Gatsby image Style
 * @type {GatsbyImage}
 */
const TeaserImg = styled(Img)`
  height: 100%;

  ${mq.medium`
        min-height: ${remCalc(600)};
   `}
`;

/**
 * Headline für den Desktopviewport
 * @type {StyledComponent}
 */
const DesktopHeadline = styled.div`
  display: none;
  ${mq.large`display: block;`}
  position: absolute;
  top: 40px;
  ${mq.mlarge`top: 40px;`}
  left: -30%;
  z-index: 2;
`;

/**
 * Container helper
 * @type {StyledComponent}
 */
const StyledForm = styled.img`
  max-width: ${remCalc(60)};
  ${mq.medium`
        max-width: ${remCalc(80)};
    `}
`;

/**
 * Container helper
 * @type {StyledComponent}
 */
const ContainerHelper = styled.div`
  margin-bottom: 0px;
  ${mq.mlarge`
        margin-bottom: 60px;
    `}
`;

/**
 * Headline für den Mobileviewport
 * @type {StyledComponent}
 */
const MobileHeadline = styled.div`
  display: block;
  ${mq.large`display: none;`}
`;

/**
 * Intro Komponente
 * @param {string} props.image Bild von Stuttgart
 */
const Intro = ({ image }) => {
  return (
    <Section id="agentur" size={[60, 150]}>
      <Grid verticalAlign="flex-end">
        <GridCell large={6} medium={6}>
          <Wrapper>
            <Container width={500} center>
              <ContainerHelper>
                <Container width={100} gap="m">
                  <ContainerHelper>
                    <StyledForm src={Form} alt="Form" />
                  </ContainerHelper>
                </Container>
                <MobileHeadline>
                  <Headline level="h2">
                    Perfekte Wellenlänge. Klare Signale. re:spkt – immer eine gute Idee.
                  </Headline>
                </MobileHeadline>
                <Paragraph fontSize="s" gap="l">
                  Was wir machen, das tun wir aus Überzeugung und deshalb richtig gut: Kommunikation
                  die wirkt – die Image stärkt, Produkte und Services verkauft, Leads generiert und
                  Ihnen hilft, Ihre Ziele zu erreichen. Dafür füllen wir Ihre Marke mit Leben,
                  bringen Ihre Kampagne zum Laufen und Ihre Botschaften in die Köpfe Ihrer
                  Zielgruppe. In B2B und B2C.
                </Paragraph>
                <Paragraph fontSize="s">
                  Wir schaffen auf allen sinnvollen Kanälen Aufmerksamkeit, wecken Neugier und
                  liefern Argumente. In sympathischer, spannender und überzeugender Art und Weise.
                  Strategisch durchdacht und immer auf Basis einer starken, unverwechselbaren Idee –
                  entwickelt in Stuttgart, wirksam weltweit.
                </Paragraph>
              </ContainerHelper>
            </Container>
          </Wrapper>
        </GridCell>
        <GridCell large={6} medium={6} alignSelf="middle">
          <Wrapper>
            <DesktopHeadline>
              <Container>
                <HL>Perfekte Wellenlänge.</HL>
                <HL>Klare Signale. re:spkt –</HL>
                <HL>immer eine gute Idee.</HL>
              </Container>
            </DesktopHeadline>
            <TeaserImg fluid={image} alt="Stuttgart"></TeaserImg>
          </Wrapper>
        </GridCell>
      </Grid>
    </Section>
  );
};
export default Intro;

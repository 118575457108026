import React, { createRef, useEffect } from 'react';
import lottie from 'lottie-web';
import styled from 'styled-components';

import { mq } from '../../stylehelpers/mixins';

import BatchAnimation from '../../images/animations/batch-animation1.json';
import BlitzAnimation from '../../images/animations/blitz-animation.json';
import FireAnimation from '../../images/animations/fire-animation.json';
import FlagAnimation from '../../images/animations/flag-animation.json';
import LayerAnimation from '../../images/animations/layer-animation.json';
import PaperPlaneAnimation from '../../images/animations/paper-plane-animation.json';

const Wrapper = styled.div`
  height: 60px;
  max-height: 60px;
  margin: 0 auto;

  ${({ animation }) =>
    animation === 'flag'
      ? `width: 73px;`
      : animation === 'layer' || animation === 'paper-plane'
      ? `width: 53px;`
      : `width: 43px;`}

  ${mq.medium`
        height: 115px;
        max-height: 115px;
        ${({ animation }) =>
          animation === 'flag'
            ? `width: 150px;`
            : animation === 'layer' || animation === 'paper-plane'
            ? `width: 105px;`
            : `width: 90px;`}
    `}

  ${mq.large`
        height: 115px;
        max-height: 115px;
        ${({ animation }) =>
          animation === 'flag'
            ? `width: 150px;`
            : animation === 'layer' || animation === 'paper-plane'
            ? `width: 105px;`
            : `width: 90px;`}
    `}
`;

const Anim = ({ animation }) => {
  let animationContainer = createRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData:
        animation === 'batch'
          ? BatchAnimation
          : animation === 'blitz'
          ? BlitzAnimation
          : animation === 'fire'
          ? FireAnimation
          : animation === 'flag'
          ? FlagAnimation
          : animation === 'layer'
          ? LayerAnimation
          : animation === 'paper-plane'
          ? PaperPlaneAnimation
          : null,
    });
    return () => anim.destroy(); // optional clean up for unmounting
  }, []);

  return (
    <Wrapper animation={animation}>
      <div ref={animationContainer}></div>
    </Wrapper>
  );
};
export default Anim;

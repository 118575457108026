import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { fontSizable, remCalc, vwMinMax } from '../../stylehelpers/mixins';
import { breakpoint, fontFamily, gaps } from '../../stylehelpers/variables';
import { Paragraph, Container, Button } from '..';
import { convertToUrlString } from '../../utils/utils';
const propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

/**
 * Styled paragraph
 * @type {StyledComponent}
 */
const StyledDescription = styled.div`
  p {
    margin-top: ${remCalc(12)};
    ${vwMinMax('font-size', 14, 17, breakpoint.medium)}
    ${gaps.s}
  }

  strong {
    color: black;
    font-weight: 400;
    ${vwMinMax('font-size', 15, 18, breakpoint.medium)}
  }

  ul {
    margin: ${remCalc(0, 0, 25, 0)};
    padding-left: ${remCalc(20)};
  }

  li {
    ${vwMinMax('font-size', 14, 17, breakpoint.medium)}
  }

  &:last-child {
    color: ${gaps.xl};
  }
`;

const StyledTitle = styled.h3`
  ${vwMinMax('font-size', 20, 24, breakpoint.medium)}
  color: black;
  font-family: ${fontFamily.base};
  ${gaps.l}
`;

/**
 * Wrapper
 * @type {StyledComponent}
 */
const Wrapper = styled.div`
  animation-name: anim;
  animation-duration: 0.6s;

  @keyframes anim {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

/**
 * Stellenbeschreibungs Komponente
 * @param {string} props.title Name der Stellenbeschreibung
 * @param {string} props.description Inhalt der Stellenbeschreibung
 * @param {string} props.mail Email auf die sich beworben werden soll
 * @param {string} props.subject Betreff die die Mail enthalten soll
 */
const JobDescription = ({ title, description, mail, subject }) => {
  const subjectUrl = convertToUrlString(subject);
  return (
    <Wrapper>
      <Container gap="xxl">
        {title && <StyledTitle>{title}</StyledTitle>}
        <Container gap="m">
          <StyledDescription dangerouslySetInnerHTML={{ __html: description }} />
        </Container>
        <Container width={320}>
          <Button fontSize="m" to={`mailto:${mail}?subject=${subjectUrl}`} arrow>
            Jetzt bewerben
          </Button>
        </Container>
      </Container>
    </Wrapper>
  );
};

JobDescription.propTypes = propTypes;

export default JobDescription;

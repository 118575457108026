import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import {
  Grid,
  GridCell,
  Paragraph,
  Section,
  Container,
  Headline,
  GridContainer,
  Card,
} from '../../components';
import { color } from '../../stylehelpers/variables';
import { mq } from '../../stylehelpers/mixins';

/**
 * Styled Line
 * @type {StyledComponent}
 */
const StyledLine = styled.hr`
    width: 40%;
    margin-left: 19px;
    ${mq.large`
        margin-left: -19px;
    `}
    border: 1px solid ${color.primary};
`;

/**
 * Styled Line
 * @type {StyledComponent}
 */
const StyledLineRight = styled.hr`
    width: 70%;
    margin-right: 19px;
    ${mq.large`
        margin-right: -19px;
    `}
    border: 1px solid ${color.primary};
`;

const BulletPointLine = styled.div`
  position: absolute;
  z-index: 3;
  left: 15px;
  top: 0;
  height: 100%;
  width: 50%;
`;

const BulletPoint = styled.div`
  margin-left: -11px;
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid ${color.primary};
`;

const BulletLines = styled.div`
  width: 50%;
  height: 40%;
  border-left: 2px solid ${color.primary};
  border-bottom: 2px solid ${color.primary};
`;

/**
 * Komponente, die die Mitarbeiter von respkt anzeigt
 * @param {string} props.controller Bild von einem Controller
 * @param {object} props.data Mitarbeiter Daten aus Prismic
 */
const Team = ({ controller, data }) => {
  return (
    <>
      <Section size={[30, 150]}>
        <GridContainer width={1250}>
          <StyledLine></StyledLine>
          <StyledLineRight></StyledLineRight>
          <Grid align="center">
            {data.map((d, index) => {
              return (
                <GridCell large={3} medium={4} small={6} key={index}>
                  <Card
                    name={d.node.data.employee_name.text}
                    title={d.node.data.employee_job.text}
                    image={d.node.data.employee_image.localFile.childImageSharp.fluid}
                  ></Card>
                </GridCell>
              );
            })}
          </Grid>
          <Grid>
            <GridCell large={6} alignSelf="middle">
              <Container width={500} center>
                <Headline level="h2">Wo Ihr Erfolg die Hauptrolle spielt</Headline>
                <Paragraph fontSize="s" gap="l">
                  Wir sind ein eingespieltes Team aus Kommunikationsprofis, in dem sich
                  Qualifikation, Expertise und Persönlichkeit perfekt ergänzen – und zu einer
                  kompakten, agilen und leistungsfähigen Fullservice-Agentur verbinden.
                </Paragraph>
                <Paragraph fontSize="s">
                  Ein echter Partner, der Ihre Interessen in den Mittelpunkt stellt und Sie dabei
                  unterstützt, effizient und auf direktem Wege bei Ihrer Zielgruppe anzukommen – und
                  dort in bester Erinnerung zu bleiben. Gefällt Ihnen? Los geht‘s!
                </Paragraph>
              </Container>
            </GridCell>
            <GridCell large={6}>
              <Container>
                <BulletPointLine>
                  <BulletPoint />
                  <BulletLines />
                </BulletPointLine>
                <Img fluid={controller}></Img>
              </Container>
            </GridCell>
          </Grid>
        </GridContainer>
      </Section>
    </>
  );
};
export default Team;

import React from 'react';
import styled from 'styled-components';

import ScrollIcon from '../../images/scroll-icon.svg';
import { remCalc } from '../../stylehelpers/mixins';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledP = styled.p`
  color: white;
  font-size: 12px;
  margin: 0;
`;

const ScrollDown = () => {
  return (
    <Wrapper>
      <img src={ScrollIcon} />
      <div>
        <StyledP>scroll</StyledP>
        <StyledP>down</StyledP>
      </div>
    </Wrapper>
  );
};
export default ScrollDown;

import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import { fontFamily } from '../../stylehelpers/variables';
import { mq, fontSizable, gapAble, remCalc, vwMinMax } from '../../stylehelpers/mixins';

import { Container, Headline, ImageGrid, Line } from '../../components';

/**
 * Wrapper des Contents
 * @type {StyledComponent}
 */
const ContentHolder = styled.div`
  padding: ${remCalc(10, 0)};
  ${mq.large`padding: ${remCalc(20)};`}
`;

/**
 * Styles für den Inhalt der Beschreibung
 * @type {StyledComponent}
 */
const StyledDescription = styled.div`
    p {
        ${vwMinMax('font-size', 14, 18)}
        ${gapAble('m')}
    }

    h3 {
        ${gapAble('m')}
        font-family: ${fontFamily.base};
        color: black;
        line-height: 1.2;
    }
`;

/**
 * Komponente, die eine Bildbühne und Text für Referenzen anzeigt
 * @param {object} props.data Daten aus Prismic
 */
const ReferenzContent = ({ data }) => {
  return (
    <>
      {data.body.map(d => {
        switch (d.slice_type) {
          case 'multiple_image_stage':
            return (
              <Container key={d.id}>
                <ImageGrid
                  image1={d.primary.image_left_large.localFile.childImageSharp.fluid}
                  image2={d.primary.image_upper_small.localFile.childImageSharp.fluid}
                  image3={d.primary.image_lower_small.localFile.childImageSharp.fluid}
                />
              </Container>
            );
          case 'solo_image_stage':
            return (
              <Container center key={d.id}>
                <Img fluid={d.primary.solo_image.localFile.childImageSharp.fluid} />
              </Container>
            );
          default:
            break;
        }
      })}
      <Container width={1100} center>
        <ContentHolder>
          <Headline level="h3">{data.referenz_title.text}</Headline>
          <StyledDescription
            dangerouslySetInnerHTML={{
              __html: data.referenz_text.html,
            }}
          ></StyledDescription>
        </ContentHolder>
      </Container>
      <Line />
    </>
  );
};
export default ReferenzContent;

import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';

import {
  Grid,
  GridCell,
  Paragraph,
  Section,
  Container,
  Headline,
  GridContainer,
} from '../../components';
import { color } from '../../stylehelpers/variables';
import { mq, remCalc } from '../../stylehelpers/mixins';

/**
 * Mobile Komponente
 * @type {StyledComponent}
 */
const StarHolderMobile = styled.div`
  display: block;
  ${mq.large`display: none;`}
`;

/**
 * Bulletpoints
 * @type {StyledComponent}
 */
const BulletPoint = styled.div`
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid ${color.primary};
  margin-right: 10px;
  box-sizing: border-box;
`;

/**
 * Point holder
 * @type {StyledComponent}
 */
const Pointholder = styled.div`
  display: flex;
`;

/**
 * Desktop Komponente
 * @type {StyledComponent}
 */
const StarHolderDesktop = styled.div`
  display: none;
  ${mq.large`display: block;`}
  position: relative;
  padding: 0px 15px 50px 15px;
  overflow: hidden;
`;

/**
 * Textholder 1
 * @type {StyledComponent}
 */
const TextHolder = styled.div`
    position: absolute;
    z-index: 1;
    ${({ left }) => (left ? `left: ${left}%;` : null)}
    ${({ top }) => (top ? `top: ${top}%;` : null)}
    ${({ right }) => (right ? `right: ${right}%;` : null)}
    ${({ bottom }) => (bottom ? `bottom: ${bottom}%;` : null)}
`;

/**
 * Headline Holder
 * @type {StyledComponent}
 */
const HeadlineHolder = styled.div`
  padding: 0px 15px;
  ${mq.large`padding: 0px;`}
`;

const MobileLine = styled.div`
  display: none;

  ${mq.smallOnly`
        display: block;
        width: 38%;
        height: 60%;
        position: absolute;
        left: 25px;
        bottom: -25px;
        z-index: 2;
        border-left: 2px solid ${color.primary};
        border-top: 2px solid ${color.primary};
    `}
`;

const Bullet = styled.div`
    position: absolute;
    height: ${remCalc(26)};
    width: ${remCalc(26)};
    ${({ left }) => (left ? `left: ${left}%;` : null)}
    ${({ top }) => (top ? `top: ${top}%;` : null)}
    ${({ right }) => (right ? `right: ${right}%;` : null)}
    ${({ bottom }) => (bottom ? `bottom: ${bottom}%;` : null)}
    z-index: 3;

    ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : `text-align: left;`)}

    &:before {
        content: '';
        position: relative;
        display: block;
        width: 300%;
        height: 300%;
        box-sizing: border-box;
        margin-left: -100%;
        margin-top: -100%;
        border-radius: 50%;
        background-color: transparent;
        animation: pulse-ring 1.55s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }

    &:after {
        transform-origin: center;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 50%;
        box-shadow: inset 0px 0px 0px 1px ${color.primary};
        animation: pulse-dot 5.55s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
        ${({ delay }) => (delay ? `animation-delay: ${delay}s;` : null)}
    }

    @keyframes pulse-dot {
        0% {
            transform: scale(0.8);
        }
        20% {
            transform: scale(1.1);
        }
        40% {
            transform: scale(0.8);
        }
        60% {
            transform: scale(0.8);
        }
        100% {
            transform: scale(0.8);
        }
    }

    @keyframes pulse-ring {
        0% {
            transform: scale(0.63);
        }
        80%,
        100% {
            opacity: 0;
        }
    }
`;
/**
 * Komponente, die die Gründe für respkt auflistet
 * @param {string} props.image Desktop Bild
 * @param {string} props.imageMobile Mobiles Bild
 */
const Reasons = ({ image, imageMobile }) => {
  return (
    <Section>
      <GridContainer width={1350}>
        <Container textAlign="center" gap="xxl">
          <HeadlineHolder>
            <Headline level="h2" gap="xxl">
              Wie wertvolle Kommunikation entsteht
            </Headline>
          </HeadlineHolder>
        </Container>
        <StarHolderDesktop>
          <Bullet delay={0} top={23.9} left={2.7} />
          <Bullet delay={1} top={9.8} right={32.3} />
          <Bullet delay={2} bottom={16.8} right={11.7} />
          <Bullet delay={3} bottom={19.3} left={26.7} />
          <Bullet delay={4} top={43.2} right={1.1} />
          <TextHolder top={8} left={3}>
            <Container width={350}>
              <Paragraph fontSize="s">
                Kommunikation mit Tiefgang, Erfolg durch Teamplay. Nur wer den Dingen wirklich auf
                den Grund geht und Spaß am gemeinsamen Schaffen hat, erzielt Ergebnisse von Wert.
              </Paragraph>
            </Container>
          </TextHolder>
          <TextHolder top={9} right={7}>
            <Container width={310}>
              <Paragraph fontSize="s">
                Fullservice neu gedacht. Was wir am besten können, macht unser Kernteam komplett
                selbst. Bei allem Anderen unterstützen uns starke Partner.
              </Paragraph>
            </Container>
          </TextHolder>
          <TextHolder bottom={2.5} right={8.2}>
            <Container width={380}>
              <Paragraph fontSize="s">
                Seit einem Jahr Agentur, seit zehn Jahren ein Team. Wir sind ein praxiserprobtes,
                eingespieltes Team. Als kompakte Einheit unterstützen wir Sie kreativ und effizient
                bei Ihrer Kommunikation.
              </Paragraph>
            </Container>
          </TextHolder>
          <TextHolder top={32} right={0.1}>
            <Container width={310}>
              <Paragraph fontSize="s">
                re:spkt basiert auf Respekt – für uns das Fundament jeder nachhaltigen
                Zusammenarbeit und Partnerschaft.
              </Paragraph>
            </Container>
          </TextHolder>
          <TextHolder bottom={10.5} left={2.2}>
            <Container width={310}>
              <Paragraph fontSize="s">
                Triple A für Ihren Erfolg. re:spkt steht für gegenseitige Achtung, für unseren
                Anspruch an die eigene Arbeit – und nicht zuletzt auch für die Anerkennung, die wir
                uns damit verdienen.
              </Paragraph>
            </Container>
          </TextHolder>
          <Img fluid={image}></Img>
        </StarHolderDesktop>
        <StarHolderMobile>
          <Container gap="xxxl" width={800} center>
            <MobileLine />
            <Img fluid={imageMobile}></Img>
          </Container>
          <Grid>
            <GridCell medium={4}>
              <Container width={400}>
                <Pointholder>
                  <BulletPoint></BulletPoint>
                  <Paragraph fontSize="s" gap="m">
                    Kommunikation mit Tiefgang, Erfolg durch Teamplay. Nur wer den Dingen wirklich
                    auf den Grund geht und Spaß am gemeinsamen Schaffen hat, erzielt Ergebnisse von
                    Wert.
                  </Paragraph>
                </Pointholder>
              </Container>
            </GridCell>
            <GridCell medium={4}>
              <Container width={400}>
                <Pointholder>
                  <BulletPoint></BulletPoint>
                  <Paragraph fontSize="s" gap="m">
                    Fullservice neu gedacht. Was wir am besten können, macht unser Kernteam komplett
                    selbst. Bei Allem anderen unterstützen uns starke Partner.
                  </Paragraph>
                </Pointholder>
              </Container>
            </GridCell>
            <GridCell medium={4}>
              <Pointholder>
                <BulletPoint></BulletPoint>
                <Paragraph fontSize="s" gap="m">
                  Seit einem Jahr Agentur, seit zehn Jahren ein Team. Wir sind ein praxiserprobtes,
                  eingespieltes Team. Als kompakte Einheit unterstützen wir Sie kreativ und
                  effizient bei Ihrer Kommunikation.
                </Paragraph>
              </Pointholder>
            </GridCell>
            <GridCell medium={4}>
              <Container width={400}>
                <Pointholder>
                  <BulletPoint></BulletPoint>
                  <Paragraph fontSize="s" gap="m">
                    re:spkt basiert auf Respekt – für uns das Fundament jeder nachhaltigen
                    Zusammenarbeit und Partnerschaft.
                  </Paragraph>
                </Pointholder>
              </Container>
            </GridCell>
            <GridCell medium={4}>
              <Container width={400}>
                <Pointholder>
                  <BulletPoint></BulletPoint>
                  <Paragraph fontSize="s" gap="m">
                    Triple A für Ihren Erfolg. re:spkt steht für gegenseitige Achtung, für unseren
                    Anspruch an die eigene Arbeit – und nicht zuletzt auch für die Anerkennung, die
                    wir uns damit verdienen.
                  </Paragraph>
                </Pointholder>
              </Container>
            </GridCell>
          </Grid>
        </StarHolderMobile>
      </GridContainer>
    </Section>
  );
};
export default Reasons;

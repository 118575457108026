import { rem } from 'polished';

/**
 * Colors
 */
export const colors = {
  gold: '#afa073',
  white: '#FFFFFF',
  black: '#000000',

  // state colors
  success: '#0E5A99',
  warning: '#ffae00',
  alert: '#cc4b37',
};

/**
 * Gaps
 */
export const gaps = {
  none: 0,
  s: rem(4),
  m: rem(8),
  l: rem(16),
  xl: rem(24),
  xxl: rem(32),
  xxxl: rem(48),
  xxxxl: rem(60),
};

/**
 * Font Faces
 */
export const fontFaces = {
  headline: '"EB Garamond", sans-serif',
  body: '"Libre Franklin", sans-serif',
};

/**
 * Font Sizes
 */
export const fontSizes = {
  xxs: rem(12),
  xs: rem(14),
  s: rem(15),
  m: rem(18),
  l: rem(24),
  xl: rem(30),
  xxl: rem(45),
  xxxl: rem(50),
};

/**
 * Font Weights
 */
export const fontWeights = {
  default: 400,
  bold: 600,
};

/**
 * Breakpoints
 */
export const breakpoints = {
  xsmall: rem(0),
  small: rem(450),
  medium: rem(640),
  mediumDown: rem(639),
  large: rem(1024),
  largeDown: rem(1023),
  xlarge: rem(1200),
  xlargeDown: rem(1199),
  xxlarge: rem(1440),
  xxlargeDown: rem(1439),
  xxxlarge: rem(1680),
  xxxlargeDown: rem(1679),
};

/**
 * Widths
 */
export const widths = {
  content: rem(980),
  smallContent: rem(660),
};

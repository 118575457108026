import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

import { color } from '../../stylehelpers/variables';
import { mq, fontSizable, gapAble } from '../../stylehelpers/mixins';
import Arrow from '../../images/slider-arrow-left1.svg';
import ArrowRight from '../../images/slider-arrow-right1.svg';

// Slider default Styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/**
 * Slider Wrapper und slick custom styles
 * @type {StyledComponent}
 */
const Wrapper = styled.div`
  .slick-arrow {
    color: ${color.primary};
  }

  .slick-next {
    top: -20px;
    left: 30px;
    background-image: url(${ArrowRight});
    background-size: contain;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    ${mq.large`
            top: -30px;
            width: 35px;
            height: 35px;
            left: 45px;
        `}
  }

  .slick-prev {
    top: -20px;
    left: 0px;
    background-image: url(${Arrow});
    background-size: contain;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    ${mq.large`
                left: 0px;
            top: -30px;
            width: 35px;
            height: 35px;
        `}
  }

  .slick-dots li.slick-active button:before {
    color: ${color.primary};
  }

  .slick-dots li button:before {
    font-size: 10px;
  }

  .slick-prev:before,
  .slick-next:before {
    color: ${color.primary};
    content: none;
  }
`;

/**
 * Slider Komponente
 */
const SliderComponent = ({ children }) => {
  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <Wrapper>
      <Slider {...settings}>{children}</Slider>
    </Wrapper>
  );
};
export default SliderComponent;
